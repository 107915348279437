import React, { useEffect, useState } from "react";
import GoogleMapsPlaces from "../../../../services/mapsPlaces";

function DrawCircle() {
  const [map, setMap] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const [markers, setMarkers] = useState([]); // Store actual marker instances

  const insertMap = async (pointers) => {
    const mapInstance = new google.maps.Map(document.getElementById("map"), {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: new google.maps.LatLng(34.1139, -118.4068),
      zoom: 12,
    });
    setMap(mapInstance);

    // Initialize the DrawingManager
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["circle"],
      },
      circleOptions: {
        fillColor: "#b8b4b4",
        fillOpacity: 0.35,
        strokeWeight: 2,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });

    // Add the DrawingManager to the map
    drawingManager.setMap(mapInstance);

    const newMarkers = []; // Array to store actual marker instances

    pointers.forEach((position) => {
      const marker = new google.maps.Marker({
        position: position.geometry.location,
        map: mapInstance,
        title: position.name,
        icon: "/assets/images/icons/restaurant-new.svg",
      });

      newMarkers.push({ marker, data: position }); // Store marker and its associated data
      // Create an info window
      const infoWindow = new google.maps.InfoWindow({
        content: `<div>
              <h3>${position.name}</h3>                     
              <p><strong>Vicinity:</strong> ${position.vicinity.toString()}</p>                      
            </div>`,
      });

      // Add a click listener to open the info window
      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });
    });

    setMarkers(newMarkers); // Update state with the new markers

    let currentCircle = null;

    google.maps.event.addListener(
      drawingManager,
      "circlecomplete",
      function (circle) {
        if (currentCircle) {
          currentCircle.setMap(null);
        }
        currentCircle = circle;
        const circleCenter = circle.getCenter();
        const circleRadius = circle.getRadius();

        mapInstance.fitBounds(circle.getBounds());

        const filteredMarkers = newMarkers.filter(({ marker, data }) => {
          const markerPosition = data.geometry.location;
          const distanceFromCenter =
            google.maps.geometry.spherical.computeDistanceBetween(
              circleCenter,
              markerPosition
            );

          return distanceFromCenter <= circleRadius;
        });

        updateMarkerList(filteredMarkers.map(({ data }) => data));

        // Filter markers and show/hide based on their distance to the circle's center
        // newMarkers.forEach(({ marker, data }) => {
        //   const markerPosition = data.geometry.location;
        //   const distanceFromCenter =
        //     google.maps.geometry.spherical.computeDistanceBetween(
        //       circleCenter,
        //       markerPosition
        //     );

        //   if (distanceFromCenter <= circleRadius) {
        //     marker.setMap(mapInstance); // Show marker within the circle
        //   } else {
        //     marker.setMap(null); // Hide marker outside the circle
        //   }
        // });

        // Update the list with only visible markers
        // const filteredMarkers = newMarkers
        //   .filter(({ marker }) => marker.getMap() !== null)
        //   .map(({ data }) => data);

        // updateMarkerList(filteredMarkers);
      }
    );
  };

  const updateMarkerList = (filteredMarkers) => {
    setMarkerData(filteredMarkers);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces({
      latitude: 34.1139,
      longitude: -118.4068,
      type: "shopping",
      radius: 500,
    });

    setMarkerData(GetNearPlaces.results);
    await insertMap(GetNearPlaces.results);
  };
  let currentInfoWindow = null; // Variable to store the currently open InfoWindow
  const handleListItemClick = (data) => {
    const markerEntry = markers.find(
      ({ data: markerData }) => markerData.place_id === data.place_id
    );

    if (markerEntry && map) {
      const { marker } = markerEntry;
      // Close the previously open InfoWindow
      if (currentInfoWindow) {
        currentInfoWindow.close();
      }
      const infoWindow = new google.maps.InfoWindow({
        content: `
          <div>
            <h3>${data.name}</h3>
            <p><strong>Vicinity:</strong> ${data.vicinity.toString()}</p>
          </div>
        `,
      });

      infoWindow.open(map, marker);

      // Update the reference to the currently open InfoWindow
      currentInfoWindow = infoWindow;

      map.setCenter(marker.getPosition()); // Center the map on the marker
    } else {
      console.log("Either marker or map is undefined.");
    }
  };

  return (
    <div>
      <div>
        <div id="map" style={{ height: "500px", width: "100%" }}></div>
      </div>
      <div className="point-tails">
        {markerData.length > 0 ? (
          markerData.map((data, index) => (
            <div key={index} onClick={() => handleListItemClick(data)}>
              <h3 style={{ cursor: "pointer" }}>{data.name}</h3>
            </div>
          ))
        ) : (
          <h3 className="no-content-msg MiddleText">
            There are no points of interest available. Please select another
            category.
          </h3>
        )}
      </div>
    </div>
  );
}

export default DrawCircle;
