import ThirdPartyApiService from "../../services/thirdPartyService";
import * as Geolib from "geolib";
const publicIp = require("react-public-ip");
import AppServices from "../../services/apiServices";
//constants
const myDestinationUrl = "https://www.relonavigator.com";

const AppController = {
  async menuTopNavigation(idName) {
    $("html, body").animate(
      {
        scrollTop: $("#" + idName).offset().top,
      },
      400
    );
  },
  async zillowApi(data) {
    const Response = await ThirdPartyApiService.getzillowResp({
      url: data.URL,
    });
    return Response;
  },
  async zillowApiWidget(data) {
    const Response = await ThirdPartyApiService.getzillowRespWidget({ data });
    return Response;
  },
  async banglowApi(data) {
    const Response = await ThirdPartyApiService.getbanglowResp({
      urldata: data.URLDATA,
    });
    return Response;
  },
  async banglowMarketsApi() {
    const Response = await ThirdPartyApiService.getbangalowMarkets();
    return Response;
  },
  async transitDada(data) {
    //  const envAWS = await ThirdPartyApiService.envAWS();
    let url =
      "http://transit.walkscore.com/transit/search/stops/?lat=" +
      data.latitude +
      "&lon=" +
      data.longitude +
      "&city=" +
      data.city_name +
      "&state=" +
      data.state_name +
      "&research=yes&wsapikey=" +
      TRANSIT_SCORE;
    const Response = await ThirdPartyApiService.transitTada({ urldata: url });
    return Response;
  },
  async neighbourhoodScount(data) {
    //let url = 'https://api.locationinc.com/api/v1/addr?auth_token=1f083e82f373c6a8d1d3dd68d329b91ae69d1a631f15d5c97375532a1b31f016&request_uuid=sdfsdf&address=428%20Tall%20Grass%20Cir,%20Lake%20Zurich,%20IL%2060047,%20USA';
    //const Response = await ThirdPartyApiService.transitTada({ urldata: url });
    return [];
  },
  async soundScoreDada(data) {
    //const envAWS = await ThirdPartyApiService.envAWS();
    let url =
      "http://elb1.howloud.com/score?key=" +
      SOUND_SCORE +
      "&longitude=" +
      data.longitude +
      "&latitude=" +
      data.latitude;
    const Response = await ThirdPartyApiService.transitTada({ urldata: url });

    return Response;
  },
  async banglowProperyApi(data) {
    const Response = await ThirdPartyApiService.getbangalowPraperty({
      urldata: data.URLDATA,
    });
    return Response;
  },
  address_component_from_google_geocode(address_components) {
    var object = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (object[addressType]) {
        var val = address_components[i][object[addressType]];
        object[addressType] = val;
      }
    }
    return object;
  },
  async busrailslines(communityData) {
    // const envAWS = await ThirdPartyApiService.envAWS();
    const Request = {
      name: "busrailslines",
      type: "TRANSIT_SCORE",
      url: this.encodeData(
        "http://transit.walkscore.com/transit/search/stops/?lat=" +
          communityData.latitude +
          "&lon=" +
          communityData.longitude +
          "&city=" +
          communityData.city_name +
          "&state=" +
          communityData.state_name +
          "&research=yes&wsapikey=TRANSIT_SCORE"
      ),
    };
    const busrailslines = await ThirdPartyApiService.thirdPartyApi(Request);
    return busrailslines.message == "error" ? {} : busrailslines;
  },
  stringChanges(request) {
    let string = request;
    string.replace(" ", "+");
    string.replace(",", "%2C");
    return string;
  },
  async setLocation(id) {
    var _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById(id)
    );
    var response = await google.maps.event.addListener(
      places,
      "place_changed",
      function () {
        var place = places.getPlace();
        let completeAddress = _this.address_component_from_google_geocode(
          places.getPlace().address_components
        );
        let address = places.getPlace().formatted_address;
        let addressLat = place.geometry.location.lat();
        let addressLng = place.geometry.location.lng();

        return {
          address: address,
          addressLat: addressLat,
          addressLng: addressLng,
          completeAddress: completeAddress,
        };
      }
    );

    return response;
  },
  encodeData(data) {
    let encodeString = data;
    for (var i = 0; i < 10; i++) {
      encodeString = Base64.encode(encodeString);
    }
    return encodeString;
  },
  decodeData(data) {
    let decodeString = data;
    for (var i = 0; i < 10; i++) {
      decodeString = Base64.decode(decodeString);
    }
    return decodeString;
  },
  getDistance(from, to) {
    var result = Geolib.getDistance(
      { latitude: from.latitude, longitude: from.longitude },
      { latitude: to.latitude, longitude: to.longitude }
    );

    return result * 0.00062137;
  },
  async getIndividualWalkscore(data, latitude, longitude) {
    // const envAWS = await ThirdPartyApiService.envAWS();
    const finalData = {
      name: "walkScore",
      type: "WALK_SCORE",
      url: this.encodeData(
        "http://api.walkscore.com/score?format=json&address=" +
          data.address +
          "&lat=" +
          data.latitude +
          "&lon=" +
          data.longitude +
          "&transit=1&bike=1&wsapikey=WALK_SCORE"
      ),
    };
    const walkscore = await ThirdPartyApiService.thirdPartyApi(finalData);
    return walkscore.walkscore;
  },
  async wallsckoreFun(communityData) {
    //const envAWS = await ThirdPartyApiService.envAWS();

    let wallsckoreVal = 0;
    let condition =
      communityData.address != "" &&
      communityData.address != undefined &&
      communityData.latitude != "" &&
      communityData.latitude != undefined
        ? true
        : false;
    if (condition) {
      const data = {
        name: "walkScore",
        type: "WALK_SCORE",
        url: this.encodeData(
          "http://api.walkscore.com/score?format=json&address=" +
            communityData.address +
            "&lat=" +
            communityData.latitude +
            "&lon=" +
            communityData.longitude +
            "&transit=1&bike=1&wsapikey=WALK_SCORE"
        ),
      };
      const wallsckore = await ThirdPartyApiService.thirdPartyApi(data);

      let walkScoreResult = [];
      if (
        Object.keys(wallsckore).length > 0 &&
        wallsckore.walkscore != undefined
      ) {
        if (wallsckore.walkscore != null) {
          walkScoreResult.push({
            Score: wallsckore.walkscore,
            description: wallsckore.description,
            iconImg: 1,
            name: "WalkScore",
            chartName: "chart1271ad132-c904-4859-b303-18dc4952cfd7",
            colour: "#fab400",
          });
        }
        if (wallsckore.bike != undefined) {
          if (wallsckore.bike.score != null) {
            walkScoreResult.push({
              Score: wallsckore.bike.score,
              description: wallsckore.bike.description,
              name: "BikeScore",
              iconImg: 2,
              chartName: "chart1271ad132-c904-4859-b303-18dc4952cfd7",
              colour: "#fab400",
            });
          }
        }
        if (wallsckore.transit != undefined) {
          if (wallsckore.transit.score != null) {
            walkScoreResult.push({
              Score: wallsckore.transit.score,
              description: wallsckore.transit.description,
              name: "TransitScore",
              iconImg: 3,
              chartName: "chart1271ad132-c904-4859-b303-18dc4952cfd7",
              colour: "#fab400",
            });
          }
        }
      } else {
        walkScoreResult.push({
          Score: 0,
          description: "No data",
          iconImg: 1,
          name: "WalkScore",
          chartName: "chart1271ad132-c904-4859-b303-18dc4952cfd7",
          colour: "#fab400",
        });

        walkScoreResult.push({
          Score: 0,
          description: "No data",
          name: "BikeScore",
          iconImg: 2,
          chartName: "chart1271ad132-c904-4859-b303-18dc4952cfd7",
          colour: "#fab400",
        });

        walkScoreResult.push({
          Score: 0,
          description: "No data",
          name: "TransitScore",
          iconImg: 3,
          chartName: "chart1271ad132-c904-4859-b303-18dc4952cfd7",
          colour: "#fab400",
        });
      }

      wallsckoreVal = walkScoreResult;
    }

    return wallsckoreVal;
  },

  async greatSchools(data) {
    console.log(data, "data");
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "qY5eRx0KYI5uSLmKVLGLX5FRh9T61Y49ae6Q9iYV",
    };
    let response = await fetch(
      "https://gs-api.greatschools.org/nearby-schools?lat=" +
        data.latitude +
        "&lon=" +
        data.longitude +
        "&limit=50&distance=20",
      { headers }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return { schools: [] };
      });
    const greatSchoolsdata = await response;
    return greatSchoolsdata;
  },
  async greatSchools2(data) {
    const Request = {
      name: "greatSchools",
      type: "greatschools",
      url: this.encodeData(
        "?&lat=" +
          data.latitude +
          "&lon=" +
          data.longitude +
          "&limit=10&distance=20"
      ),
    };
    const greatSchools = await ThirdPartyApiService.greatSchools(Request);
    return greatSchools.message == "error" ? {} : greatSchools;
  },

  async greatSchools29(data) {
    // const envAWS = await ThirdPartyApiService.envAWS();
    const Request = {
      name: "greatSchools",
      type: "GREAT_SCHOOL",
      url: this.encodeData(
        "http://api.greatschools.org/schools/nearby?state=" +
          data.state_name +
          "&lat=" +
          data.latitude +
          "&lon=" +
          data.longitude +
          "&schoolType=public-private-charter&limit=50&radius=20&key=GREAT_SCHOOL"
      ),
    };

    const greatSchools = await ThirdPartyApiService.thirdPartyApi(Request);
    return greatSchools.message == "error" ? { schools: [] } : greatSchools;
    //return greatSchools.message == "error" ? {} : {}
  },
  async greatSchoolsOverView(data) {
    // const envAWS = await ThirdPartyApiService.envAWS();
    const Request = {
      name: "greatSchools",
      type: "GREAT_SCHOOL",
      url: this.encodeData(
        "http://api.greatschools.org/cities/" +
          data.state_name +
          "/" +
          data.city_name +
          "?key=GREAT_SCHOOL"
      ),
    };

    // const greatSchools = await ThirdPartyApiService.thirdPartyApi(Request)
    //return greatSchools.message == "error" ? {} : greatSchools
    return {};
  },
  async greatSchoolsDistrict(data) {
    //  const envAWS = await ThirdPartyApiService.envAWS();
    const Request = {
      name: "greatSchools",
      type: "GREAT_SCHOOL",
      url: this.encodeData(
        "http://api.greatschools.org/districts/" +
          data.state_name +
          "/" +
          data.city_name +
          "?key=GREAT_SCHOOL"
      ),
    };

    // const greatSchools = await ThirdPartyApiService.thirdPartyApi(Request)
    // return greatSchools.message == "error" ? {} : greatSchools;
    return {};
  },
  async weather(data) {
    // envAWS = await ThirdPartyApiService.envAWS();
    const Request = {
      name: "poi",
      type: "WEATHER_KEY",
      url: this.encodeData(
        "https://api.openweathermap.org/data/2.5/forecast?lat=" +
          data.latitude +
          "&lon=" +
          data.longitude +
          "&appid=WEATHER_KEY&cnt=35&units=Imperial"
      ),
    };
    const weather = await ThirdPartyApiService.pointofinterest(Request);

    return weather.message == "error" ? {} : weather;
  },
  async weather2(data) {
    // console.log(typeof data.city, "data of the weather", data.city);
    //  const envAWS = await ThirdPartyApiService.envAWS();
    const Request = {
      name: "poi",
      type: "WEATHER_KEY",
      url: this.encodeData(
        "https://api.openweathermap.org/data/2.5/weather?q=" +
          data.city +
          ",us&appid=WEATHER_KEY&cnt=35&units=Imperial"
      ),
    };
    const weather = await ThirdPartyApiService.pointofinterest(Request);
    return weather.message == "error" ? {} : weather;
  },
  async transit(data) {
    //  const envAWS = await ThirdPartyApiService.envAWS();
    const Request = {
      name: "poi",
      type: "TRANSIT_SCORE",
      url: this.encodeData(
        "http://transit.walkscore.com/transit/score/?lat=" +
          data.latitude +
          "&lon=" +
          data.longitude +
          "&city=" +
          data.city_name +
          "&state=" +
          data.state_name +
          "&wsapikey=TRANSIT_SCORE"
      ),
    };
    const weather = await ThirdPartyApiService.thirdPartyApi(Request);

    return weather.message == "error" ? {} : weather;
  },
  async poi(data) {
    //  const envAWS = await ThirdPartyApiService.envAWS();
    const Request = {
      name: "poi",
      type: "GOOGLE_KEY",
      url: this.encodeData(
        "https://maps.googleapis.com/maps/api/place/textsearch/json?query=" +
          data.city_name +
          "+point+of+interest&language=en&required=2&key=GOOGLE_KEY"
      ),
    };
    const poi = await ThirdPartyApiService.pointofinterest(Request);
    poi.isSuccess = true;
    return poi.message == "error" ? {} : poi;
  },
  async fourSquarData(data, type) {
    // console.log(data, type, "data, type");

    //const envAWS = await ThirdPartyApiService.envAWS();
    // console.log(data, type);
    //console.log('https://api.foursquare.com/v2/venues/explore?&client_id=EIIQYCCRMHK5OWTDW41G1UUPDRVO0OC0LYYTM2QD3YRBHBX5&client_secret=AF2SB2JFJ53YPJQDFAN0FUM5ITAQEWWKOUNVOHAU1YRJBPL4&v=20190921&ll=' + data.latitude + "," + data.longitude + '&radius=3000&limit=10&categoryId=' + type);
    //console.log('https://api.foursquare.com/v2/venues/explore?&client_id=EIIQYCCRMHK5OWTDW41G1UUPDRVO0OC0LYYTM2QD3YRBHBX5&client_secret=AF2SB2JFJ53YPJQDFAN0FUM5ITAQEWWKOUNVOHAU1YRJBPL4&v=20190921&ll=' + data.latitude + "," + data.longitude + '&radius=3000&limit=10&categoryId=4f4528bc4b90abdf24c9de85')
    const Request = {
      name: "poi",
      type: "FSR_CLIENTID",
      type2: "FSR_SECRETKEY",
      url: this.encodeData(
        "https://api.foursquare.com/v2/venues/explore?&client_id=FSR_CLIENTID&client_secret=FSR_SECRETKEY&v=20190921&ll=" +
          data.latitude +
          "," +
          data.longitude +
          "&radius=8000&limit=50&categoryId=" +
          type
      ),
    };
    const fourSquar = await ThirdPartyApiService.pointofinterest(Request);
    if (fourSquar.meta != undefined)
      return fourSquar.meta.code == 200
        ? fourSquar.response.groups[0].items
        : {};
    else return [];
  },
  getMonthName(date) {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var m = new Date(date);
    var monthName = months[m.getMonth()];
    return monthName;
  },
  getDayName(date) {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var m = new Date(date);
    var dayName = days[m.getDay()];
    return dayName;
  },
  getDay(date) {
    var d = new Date(date);
    var date = d.getDate();
    return date;
  },
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getUTCMonth() + 1),
      day = "" + d.getUTCDate(),
      hours = d.getUTCHours(),
      minutes = d.getUTCMinutes(),
      seconds = d.getUTCSeconds(),
      year = d.getUTCFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return (
      [year, month, day].join("-") + " " + [hours, minutes, seconds].join(":")
    );
  },
  diff_minutes(dt2, dt1) {
    var diff = (dt2 - dt1) / 1000;
    return Math.abs(Math.round(diff));
  },
  async logData(tabname, modulename, pageurl, userUniqueId, cityName) {
    // console.log(
    //   tabname,
    //   modulename,
    //   pageurl,
    //   userUniqueId,
    //   cityName,
    //   "cityName"
    // );
    let Browsercheck = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        Browsercheck = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    let subscriptioncode =
      localStorage.getItem("loginSubscribeDetails") != null
        ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
            .subscriptioncode
        : "";

    let categotyLogDateTime = localStorage.getItem("categotyLogDateTime");
    let oldTabname = localStorage.getItem("tabname");
    let oldModulename = localStorage.getItem("modulename");
    let oldPageurl = localStorage.getItem("pageurl");
    let oldcityName = localStorage.getItem("cityName");
    let currentTime = new Date().getTime();

    localStorage.setItem("tabname", tabname);
    localStorage.setItem("modulename", modulename);
    localStorage.setItem("pageurl", pageurl);
    localStorage.setItem("cityName", cityName);
    localStorage.setItem("categotyLogDateTime", new Date().getTime());

    if (
      categotyLogDateTime != undefined &&
      oldTabname != undefined &&
      oldModulename != undefined &&
      oldPageurl != undefined
    ) {
      let ipv4 = await publicIp.v4().then(
        (ip) => {
          return ip;
        },
        function (error) {
          return "Coudn't find your IP";
        }
      );
      const createObj = {
        userid: userUniqueId,
        usertype: subscriptioncode,
        startdatetime: this.formatDate(parseInt(categotyLogDateTime)),
        enddatetime: this.formatDate(currentTime),
        ipaddress: ipv4,
        modulename: oldModulename,
        tabname: oldTabname,
        pageurl: oldPageurl,
        createdby: subscriptioncode,
        cityname: oldcityName,
        devicetype: "",
        browser: Browsercheck == false ? "Desktop" : "Mobile Browser",
        timeinmilliseconds: this.diff_minutes(currentTime, categotyLogDateTime),
      };

      // console.log("createObj:", createObj);
      let logRes = await AppServices.ManageAppAnalytics(createObj);
      //console.log("logRes:", logRes);
    }
  },
  myDestinationUrl() {
    return myDestinationUrl;
  },
};

export default AppController;
