import React, { useEffect, useState } from "react";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import { GoogleMap } from "@react-google-maps/api";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "sk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2x4b2I2M3phMDZ5cDJqcHMxZnVpMjB3bCJ9.uscOlHG0Tdfk3s2LEGX98w";

function PublicTransit(props) {
  // console.log(props, "propsline");
  const [state, setState] = useState({
    busline: "",
    railines: "",
    lines: "",
    transitMenu: "commute",
    workAddress: "",
    livingAddress: "",
    getDistance: 0,
    workAddressPreference: "",
    worklatitude: "",
    worklongitude: "",
    activeNumber: -1,
    activeMain: 0,
    map: "",
    markerBus: "",
    markerRail: "",
    loader: false,
  });
  const [map, setMap] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [minute, setMinute] = useState("10");
  const [selectedProfile, setSelectedProfile] = useState("cycling");
  useEffect(() => {
    // Show the params form after the map has loaded
    document.getElementById("params").style.display = "none";
    setState((prev) => ({ ...prev, loader: true }));
    async function fetchData() {
      try {
        let workAddress = JSON.parse(await localStorage.getItem("workAddress"));
        workAddress = workAddress.preferencesanswer;
        console.log(workAddress);
        await setState((prev) => ({
          ...prev,
          workAddress: workAddress,
        }));
        jQuery(function ($) {
          $.getJSON(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" +
              workAddress +
              "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc",
            (success) => {
              // console.log(workAddress, "success", success);
              //       setState((prev) => ({
              //         ...prev,
              //         worklatitude: success.results[0].geometry.location.lat,
              //         worklongitude: success.results[0].geometry.location.lng,
              //       }));
            }
          );
        });
      } catch (error) {
        console.log(error);
      }
      await method();
      // await getRailsData();
      await getMapboxData();
    }
    fetchData();
  }, []);

  const getRailsData = async () => {
    const lines = props.lines;
    let rails = [];
    let busline = [];
    _.filter(lines, function (o) {
      if (
        o.route_summary[0].category == "Bus" ||
        o.route_summary[0].category == "bus"
      ) {
        busline.push(o);
      }
      if (
        o.route_summary[0].category == "Rail" ||
        o.route_summary[0].category == "rail"
      ) {
        rails.push(o);
      }
    });
    setState((prev) => ({ ...prev, busline: busline, railines: rails }));
    const markerss = [];
    const markers = [];
    var map = (map = new google.maps.Map(document.getElementById("map2"), {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: new google.maps.LatLng(props.latitude, props.longitude),
      zoom: 15,
    }));
    let latlong = props.latitude + ", " + props.longitude;
    var widget = new walkscore.TravelTimeWidget({
      map: map,
      origin: latlong,
      show: true,
      zoom: 15,
      mode: walkscore.TravelTime.Mode.DRIVE,
    });
    var infowindow = new google.maps.InfoWindow();
    var marker, i;
    function clearSelectedMarker() {
      markerss.forEach(function (marker) {
        marker.setIcon("assets/images/icons/bluetrain.svg");
      });
      markers.forEach(function (marker) {
        marker.setIcon("assets/images/icons/bluebus.svg");
      });
    }
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(props.latitude, props.longitude),
      map: map,
      icon:
        props.isProperty == true
          ? "../assets/images/propertyMarker.svg"
          : "../assets/images/maps-301.svg",
    });
    let isProperty = props.isProperty;
    let city = "";
    let city1 = "";
    if (isProperty == true) {
      city = JSON.parse(
        await localStorage.getItem("myPropertyDetails")
      ).address;
    } else {
      city1 = JSON.parse(await localStorage.getItem("cDetails")).city;
    }

    google.maps.event.addListener(
      marker,
      "click",
      (function (marker, i) {
        return function () {
          let address = "";
          if (isProperty == true) {
            address = city;
          } else {
            address = city1;
          }

          var contentString = "<h6>" + address + "</h6><p>" + "</p>";
          infowindow.setContent(contentString);
          infowindow.open(map, marker);
        };
      })(marker, i)
    );
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(state.worklatitude, state.worklongitude),
      map: map,
      icon: "../assets/images/home-icon.svg",
    });
    let workAddress = JSON.parse(await localStorage.getItem("workAddress"));
    workAddress = workAddress.preferencesanswer;
    google.maps.event.addListener(
      marker,
      "click",
      (function (marker, i) {
        return function () {
          var contentString = "<h6>" + workAddress + "</h6><p>" + "</p>";
          infowindow.setContent(contentString);
          infowindow.open(map, marker);
        };
      })(marker, i)
    );
    for (i = 0; i < rails.length; i++) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(rails[i].lat, rails[i].lon),
        map: map,
        icon: "assets/images/icons/bluetrain.svg",
      });

      google.maps.event.addListener(
        marker,
        "click",
        (function (marker, i) {
          return function () {
            var datass = [];
            for (var j = 0; j < rails[i].route_summary.length; j++) {
              datass.push(rails[i].route_summary[j].name);
            }
            var contentString =
              "<h6>" + rails[i].name + "</h6><p>" + datass + "</p>";
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
            clearSelectedMarker();
            marker.setIcon("assets/images/icons/redtrain.svg");
          };
        })(marker, i)
      );
      markerss.push(marker);
    }
    for (i = 0; i < busline.length; i++) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(busline[i].lat, busline[i].lon),
        map: map,
        icon: "assets/images/icons/bluebus.svg",
      });
      google.maps.event.addListener(
        marker,
        "click",
        (function (marker, i) {
          return function () {
            var datass = [];
            for (var j = 0; j < busline[i].route_summary.length; j++) {
              datass.push(busline[i].route_summary[j].name);
            }
            var contentString =
              "<h6>" + busline[i].name + "</h6><p>" + datass + "</p>";
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
            clearSelectedMarker();
            marker.setIcon("assets/images/icons/redbus.svg");
          };
        })(marker, i)
      );
      markers.push(marker);
    }

    setState((prev) => ({
      ...prev,
      map: map,
      markerBus: markers,
      markerRail: markerss,
      busline: busline,
      railines: rails,
    }));
  };
  const getMapboxData = async () => {
    const lines = props.lines;
    let rails = [];
    let busline = [];
    _.filter(lines, function (o) {
      if (
        o.route_summary[0].category === "Bus" ||
        o.route_summary[0].category === "bus"
      ) {
        busline.push(o);
      }
      if (
        o.route_summary[0].category === "Rail" ||
        o.route_summary[0].category === "rail"
      ) {
        rails.push(o);
      }
    });

    setState((prev) => ({
      ...prev,
      busline: busline,
      railines: rails,
    }));

    const mapInstance = new mapboxgl.Map({
      container: "map2", // ID of the HTML element where the map will be rendered
      style: "mapbox://styles/mapbox/streets-v11", // Map style
      center: [props.longitude, props.latitude],
      zoom: 13,
    });
    setMap(mapInstance);

    let latlong = [props.longitude, props.latitude];

    // Add a marker for the property or default marker
    new mapboxgl.Marker({
      element: createCustomIcon(
        props.isProperty
          ? "../assets/images/propertyMarker.svg"
          : "../assets/images/maps-301.svg"
      ),
    })
      .setLngLat(latlong)
      .setPopup(
        new mapboxgl.Popup({ offset: [0, -15] }).setHTML(
          `<h6>${
            props.isProperty
              ? JSON.parse(await localStorage.getItem("myPropertyDetails"))
                  .address
              : JSON.parse(await localStorage.getItem("cDetails")).city
          }</h6>`
        )
      )
      .addTo(mapInstance);

    // Add a marker for work address
    const workAddress = JSON.parse(
      await localStorage.getItem("workAddress")
    ).preferencesanswer;
    new mapboxgl.Marker({
      element: createCustomIcon("../assets/images/home-icon.svg"),
    })
      .setLngLat([state.worklongitude, state.worklatitude])
      .setPopup(new mapboxgl.Popup().setHTML(`<h6>${workAddress}</h6>`))
      .addTo(mapInstance);

    // Function to create custom markers with icons
    function createCustomIcon(iconURL) {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(${iconURL})`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      el.style.backgroundPosition = "center"; // Center the background image
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }

    // Add rail markers
    const railMarkers = rails.map((rail) => {
      const markerElement = createCustomIcon(
        "assets/images/icons/bluetrain.svg"
      );
      const marker = new mapboxgl.Marker({
        element: markerElement,
      })
        .setLngLat([rail.lon, rail.lat])
        .setPopup(
          new mapboxgl.Popup({ offset: [0, -15] }).setHTML(
            `<h6>${rail.name}</h6><p>${rail.route_summary
              .map((r) => r.name)
              .join(", ")}</p>`
          )
        )
        .addTo(mapInstance);

      // Listen for popup open event to change the icon to red
      marker.getPopup().on("open", () => {
        markerElement.style.backgroundImage = `url(assets/images/icons/redtrain.svg)`;
      });

      // Listen for popup close event to change the icon back to blue
      marker.getPopup().on("close", () => {
        markerElement.style.backgroundImage = `url(assets/images/icons/bluetrain.svg)`;
      });

      return marker;
    });

    // Add busline markers
    const busMarkers = busline.map((bus) => {
      const markerElement = createCustomIcon("assets/images/icons/bluebus.svg");
      const marker = new mapboxgl.Marker({
        element: markerElement,
      })
        .setLngLat([bus.lon, bus.lat])
        .setPopup(
          new mapboxgl.Popup({ offset: [0, -15] }).setHTML(
            `<h6>${bus.name}</h6><p>${bus.route_summary
              .map((r) => r.name)
              .join(", ")}</p>`
          )
        )
        .addTo(mapInstance);

      // Listen for popup open event to change the icon to red
      marker.getPopup().on("open", () => {
        markerElement.style.backgroundImage = `url(assets/images/icons/redbus.svg)`;
      });

      // Listen for popup close event to change the icon back to blue
      marker.getPopup().on("close", () => {
        markerElement.style.backgroundImage = `url(assets/images/icons/bluebus.svg)`;
      });

      return marker;
    });

    setState((prev) => ({
      ...prev,
      map: mapInstance,
      markerBus: busMarkers,
      markerRail: railMarkers,
      busline: busline,
      railines: rails,
      loader: false,
    }));
    // ISOCHRONE API Integration (logic from the previous code)
    let profile = "cycling";
    let time = "10"; // Default value
    const urlBase = "https://api.mapbox.com/isochrone/v1/mapbox/";

    // Create a function to set up the Isochrone API querys
    async function getIso(inminutes, travelmode) {
      // console.log(inminutes, "minuteeeeee calling the iso", travelmode);

      const query = await fetch(
        `${urlBase}${travelmode}/${props.longitude},${props.latitude}?contours_minutes=${inminutes}&polygons=true&access_token=${mapboxgl.accessToken}`,
        { method: "GET" }
      );
      const data = await query.json();
      mapInstance.getSource("iso").setData(data); // Set Isochrone data
    }
    // When map loads, add the isochrone source and layer
    mapInstance.on("load", () => {
      mapInstance.addSource("iso", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      mapInstance.addLayer(
        {
          id: "isoLayer",
          type: "fill",
          source: "iso",
          layout: {},
          paint: {
            "fill-color": "#5a3fc0",
            "fill-opacity": 0.3,
          },
        },
        "poi-label"
      );
      // let time = minute;
      // Make the initial Isochrone API call
      getIso(time, profile);
      // Show the params form after the map has loaded
      document.getElementById("params").style.display = "block";
    });

    // Add event listeners for the params form to update profile or duration
    document.getElementById("params").addEventListener("change", (event) => {
      if (event.target.name === "profile") {
        profile = event.target.value;
        setSelectedProfile(profile);
      }
      // let time = minute;

      getIso(time, profile); // Call Isochrone API again with new parameters
    });
    document
      .getElementById("duration-slider")
      .addEventListener("input", (event) => {
        const newMinute = event.target.value;

        setMinute(newMinute);
        document.getElementById(
          "duration-label"
        ).textContent = `${newMinute} min`;
        time = newMinute; // Update the time variable with the slider value
        getIso(newMinute, profile); // Call Isochrone API again with new parameters
      });
  };

  const ChangeTransit = async (id) => {
    setState((prev) => ({ ...prev, transitMenu: id }));
    if ($("input[type=checkbox]").is(":checked") == false && id == "commute")
      $("input[type=checkbox]").click();
    if ($("input[type=checkbox]").is(":checked") == true && id != "commute")
      $("input[type=checkbox]").click();
  };
  const method = async () => {
    let myPropertyDetails = JSON.parse(
      await localStorage.getItem("myPropertyDetails")
    );
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.userUniqueId
          : myPropertyDetails.transfereeUId,
      istemporary:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.istemporary
          : false,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    let userPreferanceObject = {
      transfereeuniqeuid:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.userUniqueId
          : myPropertyDetails.transfereeUId,
      istemporary:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.istemporary
          : false,
    };
    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        if (userAnswerPreferanceObject.length > 0) {
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          let sampleAnswerArray = _.filter(
            getQuestionList.message.preferencesList,
            function (o) {
              return answersPrefer[o.preferenceUId] == undefined;
            }
          );
          if (sampleAnswerArray.length > 0) {
            let obj = sampleAnswerArray[0];
            obj.preferencesanswer = "";
            answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
          }
          setWorkAddress(answersPrefer, getQuestionList);
          setState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
        } else {
          _.forEach(
            getQuestionList.message.preferencesList,
            function (answerObject) {
              let answerDefaultVal = "";
              if (answerObject.type == "money-range") {
                answerDefaultVal = "500, 1000";
              }
              if (answerObject.type == "Time-range") {
                answerDefaultVal = "15, 30";
              }
              answerObject.preferencesanswer = answerDefaultVal;
              answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
              answerObject.preferencesmasteruniqueid =
                answerObject.preferenceUId;
              answersPrefer[answerObject.preferenceUId] = answerObject;
            }
          );
          setWorkAddress(answersPrefer, getQuestionList);
          setState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
        }
      }
    }
  };
  const setWorkAddress = async (answersPrefer, getQuestionList) => {
    // console.log(answersPrefer, getQuestionList, "hello");
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });

      const preferenceUId = workAddressArray[0]?.preferenceUId;
      if (state.answersPrefer && preferenceUId !== undefined) {
        const preferencesanswer =
          state.answersPrefer[preferenceUId]?.preferencesanswer;
        if (preferencesanswer !== undefined) {
          await localStorage.setItem("address", preferencesanswer);
        }
      }
      var geocoder = new google.maps.Geocoder();
      let workAddress = {
        lat: answersPrefer[workAddressArray[0].preferenceUId].latitude,
        lng: answersPrefer[workAddressArray[0].preferenceUId].longitude,
        workAddress:
          answersPrefer[workAddressArray[0].preferenceUId] != undefined
            ? answersPrefer[workAddressArray[0].preferenceUId].preferencesanswer
            : "",
      };
      // console.log(workAddress, "workAddress");
      setState((prev) => ({ ...prev, workAddress: workAddress }));

      if (props.isSaproperty == true) {
        let myPropertyDetails = JSON.parse(
          await localStorage.getItem("saDetails")
        );
        let livingAddress = {
          lat: props.latitude,
          lng: props.longitude,
          workAddress:
            myPropertyDetails.address +
            ", " +
            myPropertyDetails.city +
            ", " +
            myPropertyDetails.state,
        };
        setState((prev) => ({ ...prev, livingAddress: livingAddress }));
        getDistanceFunc(workAddress, livingAddress);
      } else if (props.isSaproperty == false) {
        let myPropertyDetails = JSON.parse(
          await localStorage.getItem("myPropertyDetails")
        );
        let livingAddress = {
          lat: myPropertyDetails.latitude,
          lng: myPropertyDetails.longitude,
          workAddress: myPropertyDetails.address,
        };
        setState((prev) => ({ ...prev, livingAddress: livingAddress }));
        getDistanceFunc(workAddress, livingAddress);
      }
    }
  };
  const getDistanceFunc = async (workAddress, livingAddress) => {
    if (workAddress != "" && livingAddress != "") {
      let getDistance = getDistancebtn(workAddress, livingAddress);
      setState((prev) => ({ ...prev, getDistance: getDistance }));
    }
  };
  // Function to fly to a specific place
  const flyToPlace = (row, index, type, i) => {
    if (!state.map) {
      console.error("Map instance is not initialized.");
      return;
    }

    // Determine if it's a bus or rail marker
    const placemarker = type === "bus" ? state.markerBus : state.markerRail;

    if (placemarker && placemarker.length > index) {
      setState((prev) => ({ ...prev, activeNumber: i, activeMain: index }));

      // Pan to the selected location
      state.map.flyTo({
        center: [row.lon, row.lat],
        essential: true,
        zoom: 13,
      });
      // Close the currently open popup if it exists
      if (currentPopup) {
        currentPopup.remove();
      }
      // Open the popup for the selected marker
      const markerInfo = placemarker[index];

      // Ensure markerInfo and its popup are valid
      if (markerInfo && markerInfo.getPopup()) {
        // console.log("Marker clicked", markerInfo);
        // Ensure the popup is added to the map, set location, and open the popup
        const popup = markerInfo.getPopup();
        if (popup) {
          popup.setLngLat([row.lon, row.lat]); // Ensure popup is at the correct location
          popup.addTo(state.map); // Add popup to map
          // popup.open(); // Open the popup
          setCurrentPopup(popup);
        }
        // console.error(`Marker info or popup is undefined for index=${index}`);
      }
    } else {
      console.error(
        `Invalid placemarker or index: type=${type}, index=${index}`
      );
    }
  };

  // const flyToPlace = (row, index, type, i) => {
  //   console.log(row, index, type, i, "row, index, type, i");

  //   // Check if placemarker exists and has the specified index
  //   const placemarker = type === "bus" ? state.markerBus : state.markerRail;
  //   if (placemarker && placemarker.length > index) {
  //     setState((prev) => ({ ...prev, activeNumber: i, activeMain: index }));
  //     let latLng = new google.maps.LatLng(row.lat, row.lon);
  //     // Check if map is defined and call panTo
  //     if (state.map) {
  //       state.map.panTo(latLng);
  //     }
  //     // Trigger the event only if placemarker[index] exists
  //     if (placemarker[index]) {
  //       google.maps.event.trigger(placemarker[index], "click");
  //     }
  //   } else {
  //     console.error(
  //       `Invalid placemarker or index: type=${type}, index=${index}`
  //     );
  //   }
  // };
  const rad = (x) => {
    return (x * Math.PI) / 180;
  };

  const getDistancebtn = (p1, p2) => {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d * 0.000741371).toFixed(1); // returns the distance in meter
  };
  const setLocation = async (Id, e) => {
    e.persist();

    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id)
    );

    google.maps.event.addListener(places, "place_changed", async function () {
      var place = places.getPlace();

      if (Id == "gecode1") {
        let workAddress = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        if (state.livingAddress != "") {
          let getDistance = getDistancebtn(workAddress, state.livingAddress);
          setState((prev) => ({ ...prev, getDistance: getDistance }));
        }
        setState((prev) => ({ ...prev, workAddress: workAddress }));
      }
      if (Id == "gecode2") {
        let livingAddress = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        if (state.workAddress != "") {
          let getDistance = getDistancebtn(state.workAddress, livingAddress);
          setState((prev) => ({ ...prev, getDistance: getDistance }));
        }
        setState((prev) => ({ ...prev, livingAddress: livingAddress }));
      }
    });
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  return (
    <div className="row m-0 ">
      {state.loader ? <div className="loading"> </div> : ""}
      <div className="col-md-4  p-0 rounded-start-4 overflow-hidden">
        <div className="bb-white">
          <div className="communityNew-publictransitapp citybg">
            <div id={"map2"} className="b-radius-8 relomap-height"></div>

            <div className=" testingClass">
              <form id="params">
                <h4 className="txt-m txt-bold mb6">Choose a travel mode:</h4>
                <div className="mb-4 toggle-group align-center">
                  <label className="toggle-container">
                    <input
                      name="profile"
                      type="radio"
                      value="walking"
                      checked={selectedProfile === "walking"}
                      onChange={(e) => setSelectedProfile(e.target.value)}
                    />
                    <div className="toggle toggle--active-null toggle--null">
                      Walking
                    </div>
                  </label>
                  <label className="toggle-container">
                    <input
                      name="profile"
                      type="radio"
                      value="cycling"
                      checked={selectedProfile === "cycling"}
                      onChange={(e) => setSelectedProfile(e.target.value)}
                    />
                    <div className="toggle toggle--active-null toggle--null">
                      Cycling
                    </div>
                  </label>
                  <label className="toggle-container">
                    <input
                      name="profile"
                      type="radio"
                      value="driving"
                      checked={selectedProfile === "driving"}
                      onChange={(e) => setSelectedProfile(e.target.value)}
                    />
                    <div className="toggle toggle--active-null toggle--null">
                      Driving
                    </div>
                  </label>
                </div>
                <h4 className="txt-m txt-bold mb6">
                  Choose a maximum duration:
                </h4>
                {/* <div className="mb12 mr12 toggle-group align-center">
                  <label className="toggle-container">
                    <input name="duration" type="radio" value="10" checked />
                    <div className="toggle toggle--active-null toggle--null">
                      10 min
                    </div>
                  </label>
                  <label className="toggle-container">
                    <input name="duration" type="radio" value="20" />
                    <div className="toggle toggle--active-null toggle--null">
                      20 min
                    </div>
                  </label>
                  <label className="toggle-container">
                    <input name="duration" type="radio" value="30" />
                    <div className="toggle toggle--active-null toggle--null">
                      30 min
                    </div>
                  </label>
                </div> */}
                <div className="mb12 mr12 toggle-group align-center">
                  <input
                    id="duration-slider"
                    name="duration"
                    type="range"
                    value={minute}
                    min="5"
                    max="60"
                    step="5"
                    className="slider"
                  />
                  <label htmlFor="duration-slider" id="duration-label">
                    10 min
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 p-0">
        <div className="map-right-view rounded-end-4 ">
          <span className="toggleArrow" onClick={() => heightView()}>
            <i className="fa-solid fa-angle-up"></i>
          </span>

          <div className="heightGrid">
            <div className="schools-btns">
              <a
                className={
                  state.transitMenu == "commute" ? "btn active" : "btn"
                }
                type="button"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() => ChangeTransit("commute")}
              >
                Commute
              </a>
              <a
                className={
                  state.transitMenu == "buslines" ? "btn active" : "btn"
                }
                type="button"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() => ChangeTransit("buslines")}
              >
                Bus Lines
              </a>
              <a
                className={
                  state.transitMenu == "raillines" ? "btn active" : "btn"
                }
                type="button"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() => ChangeTransit("raillines")}
              >
                Rail Lines
              </a>
            </div>

            <div className="transit-tabview">
              <div className="tab-content">
                {state.transitMenu == "raillines" && (
                  <div id="raillines">
                    <ul>
                      {state.railines != undefined &&
                      state.railines != "" &&
                      state.railines != null ? (
                        state.railines.map((row, index) => {
                          return row.route_summary.map((subrow, i) => {
                            return (
                              <li
                                key={i}
                                onClick={() =>
                                  flyToPlace(row, index, "rail", i)
                                }
                                className={
                                  state.activeNumber == i &&
                                  state.activeMain == index
                                    ? "active"
                                    : ""
                                }
                              >
                                <span className="main">
                                  {subrow.name}
                                  <span>
                                    <b>Route : </b>
                                    {row.name}
                                  </span>
                                </span>
                                <span className="mins">
                                  {row.distance.toFixed(1)} mi
                                </span>
                              </li>
                            );
                          });
                        })
                      ) : (
                        <h3 className="no-content-msg MiddleText">
                          No public transit data available
                        </h3>
                      )}
                    </ul>
                  </div>
                )}

                {state.transitMenu == "commute" && (
                  <div id="commute">
                    <p className="errorsms input-p">
                      <span>
                        <img
                          src="/assets/images/map-Orange_icon.png"
                          alt="img"
                          style={{ width: "16px" }}
                        />
                        <input
                          className="form-control"
                          placeholder="Enter work adddress"
                          onChange={(e) => setLocation("gecode1", e)}
                          type="text"
                          defaultValue={
                            Object.keys(state.workAddress).length > 0
                              ? state.workAddress.workAddress
                              : ""
                          }
                          id="gecode1"
                        />
                      </span>

                      <span>
                        <img
                          src="/assets/images/map-Green_icon.png"
                          alt="img"
                          style={{ width: "16px" }}
                        />{" "}
                        <input
                          className="form-control"
                          placeholder="Enter property/living adddress"
                          type="text"
                          id="gecode2"
                          onChange={(e) => setLocation("gecode2", e)}
                          defaultValue={
                            Object.keys(state.livingAddress).length > 0
                              ? state.livingAddress.workAddress
                              : ""
                          }
                        />
                      </span>
                    </p>
                    {state.getDistance != 0 ? (
                      <p className="errorsms">
                        <span className="dis">Distance (approx.) :</span>
                        <span id="distanceID"> {state.getDistance} Miles</span>
                      </p>
                    ) : null}
                  </div>
                )}

                {state.transitMenu == "buslines" && (
                  <div id="buslines">
                    <ul>
                      {state.busline != undefined &&
                      state.busline != "" &&
                      state.busline != null ? (
                        state.busline.map((row, index) => {
                          return row.route_summary.map((subrow, i1) => {
                            return (
                              <li
                                key={i1}
                                onClick={() =>
                                  flyToPlace(row, index, "bus", i1)
                                }
                                className={
                                  state.activeNumber == i1 &&
                                  state.activeMain == index
                                    ? "active"
                                    : ""
                                }
                              >
                                <span className="main">
                                  {subrow.name}
                                  <span>
                                    <b>Route : </b>
                                    {row.name}
                                  </span>
                                </span>
                                <span className="mins">
                                  {row.distance.toFixed(1)} mi
                                </span>
                              </li>
                            );
                          });
                        })
                      ) : (
                        <h3 className="no-content-msg MiddleText">
                          No public transit data available
                        </h3>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicTransit;
