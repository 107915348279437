import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function PublicContactUs() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  return (
    <div className="bannerPart contactUs">
      <div className="container">
        <div className="row">
          <div className="col-lg-6" data-aos="fade-down">
            <h2>Contact Us</h2>
            <p className="mb-4">
              Lorem ipsum dolor sit amet consectetur. Elementum ac viverra sed
              netus elementum. Sed velit tellus integer lectus molestie eget.
            </p>
            <ul>
              <li>
                <span>Address</span> 4517 Washington Ave. Manchester, Kentucky
                39495
              </li>
              <li>
                <span>Chat Support</span> admin@relonavigator.com
              </li>
              <li>
                <span>Call Support</span> (406) 555-0120
              </li>
              <li>
                <span>Social Media</span>

                <ul>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/linkedin.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/facebook.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/instagram.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/youtube.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/twitter.png"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-lg-6" data-aos="fade-up">
            <form>
              <div className="row mb-4">
                <div className="col">
                  <label for="first Name">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    name="first Name"
                  />
                </div>
                <div className="col">
                  <label for="last Name">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    name="last Name"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label for="pwd">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  name="pswd"
                />
              </div>
              <div className="mb-4">
                <label for="pwd">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  name="pswd"
                />
              </div>

              <div className="mb-4">
                <label for="comment">Message</label>
                <textarea
                  className="form-control"
                  rows="5"
                  name="text"
                ></textarea>
              </div>

              <div className="form-check mb-4">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="remember"
                  />
                  I'd like to receive more information about company. I
                  understand and agree to the <a href="#">Privacy Policy</a>
                </label>
              </div>

              <button type="submit" className="btn btn-black w-100 fs-5 py-2">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicContactUs;
