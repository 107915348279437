import React, { useEffect, useState } from "react";
import axios from "axios";
import AppController from "../../../controllers/appController";
import OwlCarousel from "react-owl-carousel";
import schoolData from "../cityLanding/schoolData.json";
import _ from "lodash";
import { Select } from "antd";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

function SchoolSearchMapBox(props) {
  const [state, setState] = useState({
    startPosition: "0",
    schoolsData: [],
    greatSchools: "",
    privateSchools: "",
    publicSchools: "",
    charterSchools: "",
    Loader: false,
    types: ["public", "private", "charter"],
    subTypes: [
      {
        name: "Elementary",
        type: "Elementary",
      },
      {
        name: "Middle School",
        type: "Middle",
      },
      {
        name: "High School",
        type: "High",
      },
    ],
    typeName: "Public",
    activeNumber: "",
    worklatitude: "",
    worklongitude: "",
    selectedType: "",
    selectedSchool: "public",
  });
  const [map, setMap] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  const SchoolData = schoolData;
  // console.log(SchoolData, "SchoolData");

  useEffect(() => {
    async function fetchData() {
      try {
        // console.log(props.data, "data school");
        setState((prev) => ({ ...prev, data: props.data }));
        let workAddress = JSON.parse(await localStorage.getItem("workAddress"));
        workAddress = workAddress.preferencesanswer;
        console.log(workAddress);
        await setState((prev) => ({
          ...prev,
          workAddress: workAddress,
        }));
        let address = {
          city: workAddress,
        };
        axios
          .get("/work-lat-log", { params: address })
          .then((response) => {
            let data = response.data;
            // console.log(data, "image");
            setState((prev) => ({
              ...prev,
              worklatitude: data.latitude,
              worklongitude: data.longitude,
            }));
            getSchoolData(data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const insertMap = (location, schoolData) => {
    const mapInstance = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
      center:
        location.latitude !== 0 &&
        location.latitude !== null &&
        location.longitude !== 0 &&
        location.longitude !== null
          ? {
              lat: location.latitude,
              lng: location.longitude,
            }
          : { lat: 34.0058, lng: -118.3968 }, // Initial map center [lng, lat]
      zoom: 13, // Initial map zoom level
    });
    // let markersData = mapState.poi;
    setMap(mapInstance);
    schoolData.forEach((data) => {
      //   console.log(data, "markerdata");

      // Create the marker and set its position
      const marker = new mapboxgl.Marker({
        element: createCustomMarkerElement(),
      })
        .setLngLat([data.lon, data.lat])
        .addTo(mapInstance);
      if (currentPopup) {
        currentPopup.remove();
      }
      // Create and set the popup, then attach it to the marker
      const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
         <h6>${data.name}</h6>
      <p>${data.street}, ${data.city}</p>
      <a href="${data["overview-url"]}" target="_blank">Over View</a> <!-- Corrected URL property -->
        `);

      // Attach the popup to the marker
      marker.setPopup(popup);

      // Handle popup open event
      popup.on("open", () => {
        setCurrentPopup(popup);
        setState((prev) => ({
          ...prev,
          activeMaker: data,
          activeNumber: data["universal-id"], // Corrected property name
          latitude: data.lat, // Ensure latitude and longitude are correctly used
          longitude: data.lon,
        }));
      });

      // Handle popup close event
      popup.on("close", () => {
        setState((prev) => ({
          ...prev,
          activeNumber: null,
        }));
      });
    });

    function createCustomMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg)`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      el.style.backgroundPosition = "center"; // Center the background image
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }
  };

  const getSchoolData = async (data) => {
    // console.log(data, "data");
    try {
      const greatSchools = await AppController.greatSchools2(data);
      const privateScools = _.filter(greatSchools.schools, function (o) {
        o.schoolSummary = o["school-summary"];
        o.overviewUrl = o["overview-url"];
        return o.type == "private";
      });
      const publicScools = _.filter(greatSchools.schools, function (o) {
        o.schoolSummary = o["school-summary"];
        o.overviewUrl = o["overview-url"];
        return o.type == "public";
      });
      const charterScools = _.filter(greatSchools.schools, function (o) {
        o.schoolSummary = o["school-summary"];
        o.overviewUrl = o["overview-url"];
        return o.type == "charter";
      });
      //   const privateScools = SchoolData.schools.filter(
      //     (o) => o.type === "private"
      //   );
      //   const publicScools = SchoolData.schools.filter(
      //     (o) => o.type === "public"
      //   );
      //   const charterScools = SchoolData.schools.filter(
      //     (o) => o.type === "charter"
      //   );
      insertMap(data, publicScools);
      setState((prev) => ({
        ...prev,
        schoolsData: publicScools,
        // greatSchools: SchoolData.schools,
        greatSchools: greatSchools.schools,
        privateSchools: privateScools,
        publicSchools: publicScools,
        charterSchools: charterScools,
      }));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onetype = (type) => {
    // console.log(type, "type");

    setState((prev) => ({
      ...prev,
      typeName: type,
      Loader: true,
      selectedSchool: type,
      selectedType: "",
    }));

    let data = [];
    let location = {
      latitude: state.worklatitude,
      longitude: state.worklongitude,
    };

    switch (type) {
      case "private":
        data = state.privateSchools;
        break;
      case "public":
        data = state.publicSchools;
        break;
      case "charter":
        data = state.charterSchools;
        break;
      default:
        data = [];
    }

    insertMap(location, data);
    setState((prev) => ({
      ...prev,
      schoolsData: data,
      Loader: false,
    }));
  };
  const filterSchools = async (subtype) => {
    // console.log(subtype, "subtype");
    setState((prev) => ({ ...prev, selectedType: subtype }));
    let school = state.selectedSchool;
    let location = {
      latitude: state.worklatitude,
      longitude: state.worklongitude,
    };
    // console.log(school, "schoolschool");
    let data = state.greatSchools;
    // console.log(data, "datadatadata");

    let filterData = data.filter((o) => {
      return o.name.includes(subtype) && o.type == school;
    });
    insertMap(location, filterData);
    setState((prev) => ({ ...prev, schoolsData: filterData }));
    // console.log(filterData, "filterData");
  };
  const handleMarkerInfo = (data) => {
    // console.log("handlemarker", data);
    if (!map) {
      console.error("Map instance is not available.");
      return;
    }
    setState((prev) => ({
      ...prev,
      activeMaker: data,
      activeNumber: data["universal-id"], // Corrected property name
      latitude: data.lat, // Ensure latitude and longitude are correctly used
      longitude: data.lon,
    }));
    const { name, vicinity, geometry } = data; // Extract relevant info from row
    // Center the map on the coordinates
    map.flyTo({
      center: [data.lon, data.lat], // Update coordinates to your data
      zoom: 13, // Adjust zoom level as needed
    });
    // Close the current popup if it's open
    if (currentPopup) {
      currentPopup.remove();
    }

    // Create a popup with the information
    const popup = new mapboxgl.Popup()
      .setLngLat([data.lon, data.lat])
      .setHTML(
        ` <h6>${data.name}</h6>
      <p>${data.street}, ${data.city}</p>
      <a href="${data["overview-url"]}" target="_blank">Over View</a>
        `
      )
      .addTo(map);
    // Update the currentPopup state
    setCurrentPopup(popup);
  };

  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  return (
    <div>
      <div className="row m-0">
        <div className="col-md-12 p-0"></div>
        <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
          <div className="relomap-height">
            <div id="map" style={{ width: "100%", height: "100%" }}></div>
          </div>
        </div>

        {state.Loader == true ? <div className="loading">Loading</div> : null}

        <div className="col-md-4 p-0">
          <div className="map-right-view rounded-end-4">
            <span className="toggleArrow" onClick={() => heightView()}>
              <i class="fa-solid fa-angle-up"></i>
            </span>

            <div className="heightGrid">
              <Select
                value={state.typeName}
                placeholder="Please select a City"
                onChange={(value) => onetype(value)}
              >
                <Select.Option value="" key={-1}>
                  Please Select from the list
                </Select.Option>
                {state.types.map((row, i) => (
                  <Select.Option value={row} key={row}>
                    {row.charAt(0).toUpperCase() + row.slice(1)}
                  </Select.Option>
                ))}
              </Select>

              <div className="slider mb-3">
                <div class="owl-carousel client-testimonial-carousel">
                  {state.subTypes.map((data, index) => (
                    <div key={index} className="item">
                      <a
                        className={`nav-item${
                          (state.selectedType === ""
                            ? " Elementary"
                            : state.selectedType) === data.type
                            ? " active"
                            : ""
                        }`}
                        data-toggle="tab"
                        id={data.name.replace(/[^a-zA-Z]/g, "")}
                        onClick={() => filterSchools(data.type, index)}
                      >
                        {/* <i className={type.faicon}></i> */}
                        {data.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="point-tails">
                <h2 className="text-center schoolData">
                  <a href="https://www.greatschools.org" target="_blank">
                    {" "}
                    School Data Provided by <span>GREATSCHOOLS</span>
                  </a>
                </h2>
                {state.schoolsData && state.schoolsData.length > 0 ? (
                  state.schoolsData.map((row, index) => {
                    // console.log(row, "row");

                    return (
                      <>
                        <div
                          className={
                            row["universal-id"] == state.activeNumber
                              ? "tail-view active"
                              : "tail-view"
                          }
                          key={index}
                          onClick={() => handleMarkerInfo(row)}
                        >
                          <ul>
                            <li>
                              {" "}
                              {row.rating != undefined ? (
                                <img
                                  className="rating-image"
                                  src={`/assets/images/greatSchools/ratings/${row.rating}-medium.png`}
                                />
                              ) : (
                                <img
                                  className="rating-image"
                                  src={`/assets/images/greatSchools/ratings/na-medium.png`}
                                />
                              )}
                            </li>
                            <li className="d-block">
                              <h1>{row.name}</h1>
                              <span>
                                {row.street +
                                  " , " +
                                  row.city +
                                  " , " +
                                  row.state +
                                  " " +
                                  row.zip}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <h3 className="no-content-msg MiddleText">
                    No Schools available in this category. Please check in other
                    category.
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolSearchMapBox;
