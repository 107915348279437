import React, { useEffect } from "react";
import PublicHeader from "../newpublicPage/common/publicHeader";
import PublicFooter from "./common/publicFooter";
import PublicBanner from "./common/publicBanner";
import AOS from "aos";
import "aos/dist/aos.css";

function NewPublicPage() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  let items = document.querySelectorAll(".carousel .carousel-item");

  items.forEach((el) => {
    const minPerSlide = 4;
    let next = el.nextElementSibling;
    for (var i = 1; i < minPerSlide; i++) {
      if (!next) {
        // wrap carousel by using first child
        next = items[0];
      }
      let cloneChild = next.cloneNode(true);
      el.appendChild(cloneChild.children[0]);
      next = next.nextElementSibling;
    }
  });
  return (
    <div>
      {/* <PublicHeader /> */}
      <PublicBanner />
      <div className="container my-5">
        <h2>The All-in-One Solution</h2>
        <p>
          Empowering Companies . Supporting Consultants . Guiding Individuals
        </p>

        <div className="tials">
          <div className="box" data-aos="fade-up">
            <img src="assets/publicImages/gridImage-1.png" alt="" />
            <h3>for individuals</h3>
            <p>
              ReloNavigator empowers individuals with personalized tools to
              simplify every step of the relocation. Access area information,
              school finder, neighborhood insights, home finding, DE&I data,
              moving resources, and much more to make informed decisions with
              ease
            </p>
          </div>
          <div className="box" data-aos="fade-down">
            <img src="assets/publicImages/gridImage-2.png" alt="" />
            <h3>for consultant</h3>
            <p>
              ReloNavigator equips consultants with the tools to manage
              appointments and offer personalized destination services. With
              features like personalized property recommendations, direct chat
              with transferees, and detailed reporting, you can deliver a
              tailored and seamless relocation experience
            </p>
          </div>
          <div className="box" data-aos="fade-up">
            <img src="assets/publicImages/gridImage-3.png" alt="" />
            <h3>for companies</h3>
            <p>
              ReloNavigator enables DS companies to efficiently manage
              consultants, streamline operations, and ensure top-tier service.
              With powerful reporting and tools to oversee consultant activities
              and appointments, you can deliver seamless, data-driven services
              to your clients.
            </p>
          </div>
        </div>
      </div>

      <div className="py-5">
        <div className="container">
          <div className="view bg-imageView row bg-black rounded-3 overflow-hidden mb-5">
            <div className="box-item col-md-6 ps-5 d-flex justify-content-center flex-column align-items-start">
              <h2 className="text-white" data-aos="fade-up">
                Leading the Way with Innovation
              </h2>
              <p className="text-white" data-aos="fade-up">
                Our patented technology sets us apart. ReloNavigator ensures
                accurate, reliable, and efficient relocation support that's a
                step ahead of the rest.
              </p>
              <button type="button" className="btn" data-aos="fade-up">
                Request a Demo
              </button>
            </div>
            <div className="box-image col-md-6"></div>
          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <h2 className="text-center">
            All the Tools You Need in One Platform
          </h2>
          <p className="text-center">
            Explore the features that make ReloNavigator the go-to platform for
            Destination Services
          </p>

          <div className="my-5">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="companies-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#companies-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="companies-tab-pane"
                  aria-selected="true"
                >
                  Companies
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="individuals-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#individuals-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="individuals-tab-pane"
                  aria-selected="false"
                >
                  Relocating Individuals
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="consultants-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#consultants-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="consultants-tab-pane"
                  aria-selected="false"
                >
                  Consultants
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade"
                id="companies-tab-pane"
                role="tabpanel"
                aria-labelledby="companies-tab"
                tabIndex="0"
              >
                <div className="grid-box">
                  <div className="items full">
                    <div className="tail">
                      <img
                        src="assets/publicImages/Reports-view.svg  "
                        className="report"
                        alt=""
                      />
                      <h3 className="text-white" data-aos="fade-down">
                        Reporting
                      </h3>
                      <p className="text-white">
                        Generate detailed reports in real-time, providing
                        essential insights and updates to track progress and
                        improve efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="items">
                    <div className="tail">
                      <img
                        src="assets/publicImages/Reports-view.svg"
                        className="right report"
                        alt=""
                      />
                      <h4 className="text-white" data-aos="fade-up">
                        Custom Reporting
                      </h4>
                      <p className="text-white">
                        Create detailed, custom reports to track progress,
                        highlight key data points, and provide valuable insights
                        to both consultants and clients.
                      </p>
                    </div>
                    <div className="subTail mt-3">
                      <h3 data-aos="fade-up">Manage Consultants</h3>
                      <p>
                        Effectively manage consultant activities and
                        appointments to ensure timely and efficient service
                        delivery.
                      </p>
                    </div>
                  </div>
                  <div className="items full">
                    <div className="tail">
                      <img src="assets/publicImages/grdi-tails-1.png" alt="" />
                      <h3 className="text-white" data-aos="fade-up">
                        360 degree visibility
                      </h3>
                      <p className="text-white">
                        Gain a holistic view of each transferee's journey,
                        ensuring that every step of the relocation process is
                        optimized and on track.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="individuals-tab-pane"
                role="tabpanel"
                aria-labelledby="individuals-tab"
                tabIndex="0"
              >
                <div className="grid-box">
                  <div className="items">
                    <div className="subTail mb-3">
                      <h3 data-aos="fade-up">Managing Preferences</h3>
                      <p>
                        Easily manage your preferences to tailor your relocation
                        experience and streamline the process.
                      </p>
                    </div>
                    <div className="subTail mb-3">
                      <h3 data-aos="fade-up">Area Information</h3>
                      <p>
                        Discover key information about your destination,
                        including demographics, weather, things to do and many
                        more.
                      </p>
                    </div>
                    <div className="subTail">
                      <h3 data-aos="fade-up">Checklist</h3>
                      <p>
                        Ensure no detail is missed with customized task lists
                        and reminders that keep you on track from start to
                        finish.
                      </p>
                    </div>
                  </div>
                  <div className="items full">
                    <div className="tail">
                      <img
                        src="assets/publicImages/school-finder.svg"
                        alt=""
                        className="report"
                      />
                      <h3 className="text-white" data-aos="fade-up">
                        School Finder
                      </h3>
                      <p className="text-white">
                        Access comprehensive details on schools, including
                        ratings, programs, and nearby amenities, to make
                        informed decisions.
                      </p>
                    </div>
                    <div className="subTail mt-3">
                      <h3 data-aos="fade-up">Neighborhood Data</h3>
                      <p>
                        Explore ratings, local amenities, and neighborhood
                        characteristics to choose the best fit for yo.
                      </p>
                    </div>
                  </div>
                  <div className="items">
                    <div className="subTail mb-3">
                      <h3 data-aos="fade-up">Home Finding</h3>
                      <p>
                        Easily compare homes, explore detailed information about
                        each property, and discover points of interest nearby to
                        make confident decisions.
                      </p>
                    </div>
                    <div className="subTail mb-3">
                      <h3 data-aos="fade-up">DE&I Data</h3>
                      <p>
                        Utilize comprehensive DE&I insights to support
                        relocation decisions that align with your values and
                        needs.
                      </p>
                    </div>
                    <div className="subTail">
                      <h3 data-aos="fade-up">Moving Resources</h3>
                      <p>
                        Access tools like sample renters and pet resumes, moving
                        tips, utility setup guides, social security
                        registration, packing recommendations, and much more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show active"
                id="consultants-tab-pane"
                role="tabpanel"
                aria-labelledby="consultants-tab"
                tabIndex="0"
              >
                <div className="grid-box">
                  <div className="items">
                    <div className="tail">
                      <img src="assets/publicImages/grdi-tails-1.png" alt="" />
                      <h3 className="text-white" data-aos="fade-down">
                        Appointment Scheduling
                      </h3>
                      <p className="text-white">
                        Easily organize your schedule, manage appointments, and
                        stay on top of all relocation tasks with a streamlined
                        calendar system.
                      </p>
                    </div>
                    <div className="subTail mt-3">
                      <h3 data-aos="fade-up">Message Center</h3>
                      <p>
                        Stay connected with transferees in real-time for
                        seamless communication throughout their relocation
                        process.
                      </p>
                    </div>
                  </div>
                  <div className="items full">
                    <div className="tail">
                      <img src="assets/publicImages/grdi-tails-2.png" alt="" />
                      <h4 className="text-white" data-aos="fade-up">
                        Property Recommendations
                      </h4>
                      <p className="text-white">
                        Offer personalized home recommendations based on
                        transferee preferences, ensuring the best fit for their
                        relocation needs.
                      </p>
                    </div>
                  </div>
                  <div className="items">
                    <div className="subTail mb-3">
                      <h3 data-aos="fade-up">Real-time Data</h3>
                      <p>
                        Stay up-to-date with real-time data on transferee
                        progress, appointments, property recommendations, and
                        other key relocation tasks.
                      </p>
                    </div>
                    <div className="tail">
                      <img src="assets/publicImages/grdi-tails-3.png" alt="" />
                      <h3 className="text-white" data-aos="fade-down">
                        Custom Reporting
                      </h3>
                      <p className="text-white">
                        Create detailed, custom reports to track progress,
                        highlight key data points, and provide valuable insights
                        to both consultants and clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-5 pt-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-7" data-aos="fade-down">
              <h2>Your Move, in Your Pocket</h2>
              <p className="mb-4">
                With the ReloNavigator mobile app, managing your relocation is
                easier than ever. Stay connected, track progress, and access all
                the essential tools—anytime, anywhere.
              </p>
              <ul className="tickList">
                <li>
                  <span>Real-time Updates </span> Stay informed with instant
                  notifications and updates on your services, appointments, and
                  task progress
                </li>
                <li>
                  <span>Appointment Scheduling</span> Book and manage
                  appointments on the go with ease
                </li>
                <li>
                  <span>Home Finding & Area Info</span> Access home comparisons,
                  neighborhood data, and points of interest while you're on the
                  move
                </li>
                <li>
                  <span>Checklists & Reminders</span> Stay organized with
                  personalized checklists and automated reminders at your
                  fingertips
                </li>
              </ul>

              <div className="mt-2 mb-5">
                <img
                  src="assets/publicImages/appStore.png"
                  alt=""
                  className="me-4 mb-2"
                />
              </div>
            </div>
            <div className="col-lg-5" data-aos="fade-up">
              <img
                src="assets/publicImages/logo-with-mobilebg.png"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-5">
        <div className="container">
          <h2>Innovate Destinations</h2>
          <p>
            The trusted platform designed to elevate your destination services
            with innovation and simplicity.
          </p>

          <div className="accrodianView">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up">
                <div className="accordion" id="regularAccordionRobots">
                  <div className="accordion-item">
                    <h2 id="regularHeadingFirst" className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regularCollapseFirst"
                        aria-expanded="true"
                        aria-controls="regularCollapseFirst"
                      >
                        Tailored for Professionals
                      </button>
                    </h2>
                    <div
                      id="regularCollapseFirst"
                      className="accordion-collapse collapse show"
                      aria-labelledby="regularHeadingFirst"
                      data-bs-parent="#regularAccordionRobots"
                    >
                      <div className="accordion-body">
                        <h5>Patented Technology</h5>
                        <p>
                          Our unique, patented platform delivers cutting-edge
                          tools that make managing destination services easier
                          and more efficient.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="regularHeadingSecond">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regularCollapseSecond"
                        aria-expanded="false"
                        aria-controls="regularCollapseSecond"
                      >
                        Comprehensive Solutions
                      </button>
                    </h2>
                    <div
                      id="regularCollapseSecond"
                      className="accordion-collapse collapse"
                      aria-labelledby="regularHeadingSecond"
                      data-bs-parent="#regularAccordionRobots"
                    >
                      <div className="accordion-body">
                        Comprehensive Solutions Text
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="regularHeadingSecond2">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regularCollapseSecond2"
                        aria-expanded="false"
                        aria-controls="regularCollapseSecond2"
                      >
                        Flexibility & Customization
                      </button>
                    </h2>
                    <div
                      id="regularCollapseSecond2"
                      className="accordion-collapse collapse"
                      aria-labelledby="regularHeadingSecond2"
                      data-bs-parent="#regularAccordionRobots"
                    >
                      <div className="accordion-body">
                        Flexibility & Customization text
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="regularHeadingSecond3">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regularCollapseSecond3"
                        aria-expanded="false"
                        aria-controls="regularCollapseSecond2"
                      >
                        Data-Driven Insights
                      </button>
                    </h2>
                    <div
                      id="regularCollapseSecond3"
                      className="accordion-collapse collapse"
                      aria-labelledby="regularHeadingSecond3"
                      data-bs-parent="#regularAccordionRobots"
                    >
                      <div className="accordion-body">
                        Data-Driven Insights Text
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-down">
                <img src="assets/publicImages/lappy.png" alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PublicFooter /> */}
    </div>
  );
}

export default NewPublicPage;
