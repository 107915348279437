import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
// import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import GoogleMapsPlaces from "../../services/mapsPlaces";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia3VuZGFnZWV0aGEtMTQiLCJhIjoiY20wZ2Mwc2VzMGs5cTJucjF6dGk4cDc0dyJ9.ASH3-A0NLmHMHwXD9vZw1g"; // Replace with your Mapbox access token

function MapBoxPractice() {
  const [marker, setMarker] = useState([]);
  const markersData = [
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.0661049,
          lng: -118.4092071,
        },
        viewport: {
          northeast: {
            lat: 34.06746228029149,
            lng: -118.4077120197085,
          },
          southwest: {
            lat: 34.06476431970849,
            lng: -118.4104099802915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/lodging-71.png",
      icon_background_color: "#909CE1",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/hotel_pinlet",
      name: "Mosaic Hotel",
      opening_hours: {
        open_now: true,
      },
      photos: [
        {
          height: 1192,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/116910228927580964646">Mosaic Hotel</a>',
          ],
          photo_reference:
            "AXCi2Q5olvsrczehZ1cS6K2Mt4h7FLo5AdMK8DRBhb75_0k0zx1LxqTDcMuqYdjs9Ww_009G4ESKOx7vgr3RgZVK9czo9FLBVm8MdsQdcDbZeXWOMdQnyXtiZgxHJeVOLMooDoBE5-VUbKhIog2tLpgXtBQcaNSd7KrNAzKTqSgNlQQZn90X",
          width: 2119,
        },
      ],
      place_id: "ChIJ8eoca_a7woARuF2mTk2jdME",
      plus_code: {
        compound_code: "3H8R+C8 Beverly Hills, CA, USA",
        global_code: "85633H8R+C8",
      },
      price_level: 3,
      rating: 4.1,
      reference: "ChIJ8eoca_a7woARuF2mTk2jdME",
      scope: "GOOGLE",
      types: [
        "lodging",
        "restaurant",
        "point_of_interest",
        "food",
        "establishment",
      ],
      user_ratings_total: 321,
      vicinity: "125 S South Spalding Drive, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.0691546,
          lng: -118.4060315,
        },
        viewport: {
          northeast: {
            lat: 34.0706005302915,
            lng: -118.4047746197085,
          },
          southwest: {
            lat: 34.0679025697085,
            lng: -118.4074725802915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
      icon_background_color: "#FF9E67",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
      name: "Crustacean Restaurant Beverly Hills, CA - Brunch, Lunch & Fine Dining",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 843,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/113342349133290392088">Crustacean Restaurant Beverly Hills, CA - Brunch, Lunch &amp; Fine Dining</a>',
          ],
          photo_reference:
            "AXCi2Q4osmEn3DWthcB16WpImMZaPSydwFunXfa02Hg94UgnoWlBpQ6gF-LTGlWeZ-QHEV0yka8v7XR8HVYHbQ_Y6cZF6MkhhVbzl8sfPAlKtDCrpRLTFnQ6td8Kj_HoyfggBiNjo8XQBUh5pLvaXKYZ5sN1oYKMChcKoq0OfsHSP4qH4LBs",
          width: 1500,
        },
      ],
      place_id: "ChIJIR8LPgi8woAROIpYJyrGrHY",
      plus_code: {
        compound_code: "3H9V+MH Beverly Hills, CA, USA",
        global_code: "85633H9V+MH",
      },
      price_level: 3,
      rating: 4.4,
      reference: "ChIJIR8LPgi8woAROIpYJyrGrHY",
      scope: "GOOGLE",
      types: ["restaurant", "point_of_interest", "food", "establishment"],
      user_ratings_total: 978,
      vicinity: "468 North Bedford Drive, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.06880939999999,
          lng: -118.3988906,
        },
        viewport: {
          northeast: {
            lat: 34.07010908029149,
            lng: -118.3975964697085,
          },
          southwest: {
            lat: 34.0674111197085,
            lng: -118.4002944302915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
      icon_background_color: "#FF9E67",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
      name: "The Penthouse at Mastro's",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 1152,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/101256417971751475316">The Penthouse at Mastro&#39;s</a>',
          ],
          photo_reference:
            "AXCi2Q6ghlT2Za_aKXG9ZuWNNypNMQlZ0wwQlufdrgC67PFFb1_Nqt-LoaETD-ivzqQNdzRweVS_jE1NHNr7dPdbaQcedXhg86g1BWzcACzNwsW99HApyy067aIVzmR04Us86To708SznT2hPbXmD_OTBDfYhuXGnsaiUdVBnDio-D5YcDXl",
          width: 2048,
        },
      ],
      place_id: "ChIJcwJitfi7woARO4Dd20lT_WA",
      plus_code: {
        compound_code: "3J92+GC Beverly Hills, CA, USA",
        global_code: "85633J92+GC",
      },
      price_level: 4,
      rating: 4.5,
      reference: "ChIJcwJitfi7woARO4Dd20lT_WA",
      scope: "GOOGLE",
      types: ["restaurant", "point_of_interest", "food", "establishment"],
      user_ratings_total: 2833,
      vicinity: "246 North Canon Drive Floor 3, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.0685256,
          lng: -118.4008381,
        },
        viewport: {
          northeast: {
            lat: 34.0698451802915,
            lng: -118.3994034197085,
          },
          southwest: {
            lat: 34.0671472197085,
            lng: -118.4021013802915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
      icon_background_color: "#FF9E67",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
      name: "Il Fornaio Beverly Hills",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 2048,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/117742245245215660384">Il Fornaio Beverly Hills</a>',
          ],
          photo_reference:
            "AXCi2Q57ws6V2sIqW-jBw8-n2-EipVN32rFloXMHHgDS9N5M7VIp90rAlgs79axgTK_hdVpbol-T8v1qK-HI5CMPr7yWC-2-w8G8vobg9JUtkzzJFszz-zuHhXEc1VkFuA7mb65pbwXb2pX2WoHhzDz_9fhpX1B-1akcwe-w169hx3_50zSq",
          width: 1626,
        },
      ],
      place_id: "ChIJ_5-PlPi7woAR4ZpYUV1GScQ",
      plus_code: {
        compound_code: "3H9X+CM Beverly Hills, CA, USA",
        global_code: "85633H9X+CM",
      },
      price_level: 2,
      rating: 4.3,
      reference: "ChIJ_5-PlPi7woAR4ZpYUV1GScQ",
      scope: "GOOGLE",
      types: ["restaurant", "point_of_interest", "food", "establishment"],
      user_ratings_total: 1137,
      vicinity: "301 North Beverly Drive, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.06987119999999,
          lng: -118.4014686,
        },
        viewport: {
          northeast: {
            lat: 34.07114378029149,
            lng: -118.4002525697085,
          },
          southwest: {
            lat: 34.06844581970849,
            lng: -118.4029505302915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
      icon_background_color: "#FF9E67",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
      name: "The Cheesecake Factory",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 3072,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/108133768619997260850">Tara Hashemi</a>',
          ],
          photo_reference:
            "AXCi2Q5cb-iP41vl1Mw8HLxCr_f_Ep24MD7Q9BVRQ7hcNLgqWqHe_drRt9LiTQKedujVdEuGPyPMUClEfpQOb2RTmUoQ4WDkEocVeZ5nCge1MJ5b2cWn0RmRvZ7PzxTLDSscCnEglnNLQuziIA0FBpusp7Gu_Yjk-t4eekDf7aSlxYL1T1cU",
          width: 2830,
        },
      ],
      place_id: "ChIJ0aVRfwe8woARLc7knRDvHas",
      plus_code: {
        compound_code: "3H9X+WC Beverly Hills, CA, USA",
        global_code: "85633H9X+WC",
      },
      price_level: 2,
      rating: 4.3,
      reference: "ChIJ0aVRfwe8woARLc7knRDvHas",
      scope: "GOOGLE",
      types: [
        "restaurant",
        "bakery",
        "meal_takeaway",
        "store",
        "point_of_interest",
        "food",
        "establishment",
      ],
      user_ratings_total: 2903,
      vicinity: "364 North Beverly Drive, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.0668254,
          lng: -118.4010151,
        },
        viewport: {
          northeast: {
            lat: 34.0680859802915,
            lng: -118.3997897197085,
          },
          southwest: {
            lat: 34.06538801970851,
            lng: -118.4024876802915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
      icon_background_color: "#FF9E67",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
      name: "CUT Beverly Hills",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 1280,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/107712439645969967570">CUT Beverly Hills</a>',
          ],
          photo_reference:
            "AXCi2Q5ZEPJuMuSU99-JEL0O30xPGnXBpRy6O-wXJWDRgLLM0vf3Ww7QriQolYG8F7M8hW_HUAh9WJoVhA4PfYQKLsO-ZpHmRBlMPw5jiVFgCLdIuJRLu3ICqslKlGID4fI6lF1tgmscyWQ6W4ZhIfE813ybr7Fxi572WNEWcq5Hj8pBNtlH",
          width: 1920,
        },
      ],
      place_id: "ChIJz-22Hfm7woARxyqG7hQ_rts",
      plus_code: {
        compound_code: "3H8X+PH Beverly Hills, CA, USA",
        global_code: "85633H8X+PH",
      },
      price_level: 3,
      rating: 4.3,
      reference: "ChIJz-22Hfm7woARxyqG7hQ_rts",
      scope: "GOOGLE",
      types: ["restaurant", "point_of_interest", "food", "establishment"],
      user_ratings_total: 398,
      vicinity: "9500 Wilshire Boulevard, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.0815046,
          lng: -118.4143316,
        },
        viewport: {
          northeast: {
            lat: 34.0825242802915,
            lng: -118.4127104697085,
          },
          southwest: {
            lat: 34.0798263197085,
            lng: -118.4154084302915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
      icon_background_color: "#FF9E67",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
      name: "Polo Lounge",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 1350,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/115385145375136005007">Polo Lounge</a>',
          ],
          photo_reference:
            "AXCi2Q44GAV-YwkE1vd-wKZQ1-UQgRzpLzNcmE9qR3cL7ihDu7q8hABRUWJWsx7J6O6rKVv0zD1-iM_plJcbW6Zs9SAALOIw2PQJB8I8Xm3-A98va-nv3W6NP_RGbOfwkzmfIMwCdIO9i4QhE3AZR9DCP2lD3t7Fv8qamyusp3PtLvsr1d2m",
          width: 2400,
        },
      ],
      place_id: "ChIJy6BKexO8woARnyytEf7g64A",
      plus_code: {
        compound_code: "3HJP+J7 Beverly Hills, CA, USA",
        global_code: "85633HJP+J7",
      },
      price_level: 4,
      rating: 4.4,
      reference: "ChIJy6BKexO8woARnyytEf7g64A",
      scope: "GOOGLE",
      types: ["restaurant", "point_of_interest", "food", "establishment"],
      user_ratings_total: 608,
      vicinity: "9641 Sunset Boulevard, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.069327,
          lng: -118.4057924,
        },
        viewport: {
          northeast: {
            lat: 34.0707761802915,
            lng: -118.4045261697085,
          },
          southwest: {
            lat: 34.0680782197085,
            lng: -118.4072241302915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
      icon_background_color: "#FF9E67",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
      name: "Le Pain Quotidien",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 4032,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/104584586125570985083">Tahirah</a>',
          ],
          photo_reference:
            "AXCi2Q4WAHtpIwZxVfSMinTk_RVOyUa6KUytlwfdaPmy-4PQRRK1hKW8QzlJSYkOWEF2cd0F4YSl389A2Ax08QggIbrwmNnjlFzs8-XQ9IY5aAHZuAEcF4OlIHxpNEqud-qWQ9lofyomnNrZjnxV9A8eevd_6COXZWgV4zUerAgqk9B2DFjw",
          width: 3024,
        },
      ],
      place_id: "ChIJ2Q7jevi7woAR04eEYoLK2RM",
      plus_code: {
        compound_code: "3H9V+PM Beverly Hills, CA, USA",
        global_code: "85633H9V+PM",
      },
      price_level: 2,
      rating: 4.4,
      reference: "ChIJ2Q7jevi7woAR04eEYoLK2RM",
      scope: "GOOGLE",
      types: [
        "restaurant",
        "bakery",
        "store",
        "cafe",
        "point_of_interest",
        "food",
        "establishment",
      ],
      user_ratings_total: 403,
      vicinity: "9630 South Santa Monica Boulevard, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.0713889,
          lng: -118.4013889,
        },
        viewport: {
          northeast: {
            lat: 34.0726942802915,
            lng: -118.4001347197085,
          },
          southwest: {
            lat: 34.0699963197085,
            lng: -118.4028326802915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
      icon_background_color: "#FF9E67",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
      name: "La Scala",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 4032,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/103620381585803682988">robin delon</a>',
          ],
          photo_reference:
            "AXCi2Q4mCxjITB0YjmliK4tvqd8DAPhupL3EAkCfRZpeW7N5DqdNRiijPJIYV8Ia5zpkgIbrWq7FBApA323zFFCeLeYydcERC_kjfSvH7rw9040dzm4RL3t2aOlTF7GtmAjht6OmE-_FIKBfY2OeOwBTJXynSt1HIaeu1cl3tI8NLYOOMs_c",
          width: 3024,
        },
      ],
      place_id: "ChIJl2h7CAe8woARIelxRKYxkMo",
      plus_code: {
        compound_code: "3HCX+HC Beverly Hills, CA, USA",
        global_code: "85633HCX+HC",
      },
      price_level: 3,
      rating: 4,
      reference: "ChIJl2h7CAe8woARIelxRKYxkMo",
      scope: "GOOGLE",
      types: ["restaurant", "point_of_interest", "food", "establishment"],
      user_ratings_total: 453,
      vicinity: "434 North Canon Drive, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.0704481,
          lng: -118.4020427,
        },
        viewport: {
          northeast: {
            lat: 34.0716793302915,
            lng: -118.4007812697085,
          },
          southwest: {
            lat: 34.0689813697085,
            lng: -118.4034792302915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/shopping-71.png",
      icon_background_color: "#4B96F3",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/shopping_pinlet",
      name: "Nate'n Al's",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 6656,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/105689537497347162838">Nate&#39;n Al&#39;s</a>',
          ],
          photo_reference:
            "AXCi2Q6qKsVuD2CZK0lqypcyHn5jz3g-n3SXS3bAAHgiK3vrC0qqSDT7wjKcfY_yTXZC0S173e0lHQd0spZq8NZTYLTlbF5aYSSzbXTKfFYXM1rBG0mMWCqzqXILNoNbNt7lrOa1H_CQmC_ueGHsuzwyM0vUfQ3yzxx-1y8t_dHn3JOrZK4",
          width: 13312,
        },
      ],
      place_id: "ChIJvS4NmQe8woAR20t2p9OTp_E",
      plus_code: {
        compound_code: "3HCX+55 Beverly Hills, CA, USA",
        global_code: "85633HCX+55",
      },
      price_level: 2,
      rating: 4.3,
      reference: "ChIJvS4NmQe8woAR20t2p9OTp_E",
      scope: "GOOGLE",
      types: [
        "meal_delivery",
        "meal_takeaway",
        "restaurant",
        "store",
        "point_of_interest",
        "food",
        "establishment",
      ],
      user_ratings_total: 1277,
      vicinity: "414 North Beverly Drive, Beverly Hills",
    },
    {
      business_status: "OPERATIONAL",
      geometry: {
        location: {
          lat: 34.058518,
          lng: -118.442934,
        },
        viewport: {
          northeast: {
            lat: 34.05965398029149,
            lng: -118.4414588197085,
          },
          southwest: {
            lat: 34.05695601970849,
            lng: -118.4441567802915,
          },
        },
      },
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
      icon_background_color: "#7B9EB0",
      icon_mask_base_uri:
        "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
      name: "Casino Parties By Show Biz Productions",
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 846,
          html_attributions: [
            '\u003ca href="https://maps.google.com/maps/contrib/106461713472848279749"\u003eCasino Parties By Show Biz Productions\u003c/a\u003e',
          ],
          photo_reference:
            "AXCi2Q7Jfn6o2ZZ2_N9zBzHo1DNYO3f0FyeKGX7TY6JrF1K0-Gr-ehbEL4glNwK139UlRFhXMcNb5I6MaUB9f9QFIyK1wHSQ3e-WrdmRnsAxXEvhi4K7fWoBcN7eDQgvGzG3nYaJ0fe1NqLTCRpczwkVEAdXBKoPDABAfFsZB4sJ2SJHj1fr",
          width: 564,
        },
      ],
      place_id: "ChIJt7_QNey7woARIuq1k3YK32s",
      plus_code: {
        compound_code: "3H54+CR Los Angeles, CA, USA",
        global_code: "85633H54+CR",
      },
      rating: 5,
      reference: "ChIJt7_QNey7woARIuq1k3YK32s",
      scope: "GOOGLE",
      types: ["casino", "point_of_interest", "establishment"],
      user_ratings_total: 4,
      vicinity: "10880 Wilshire Boulevard suite 1101, Los Angeles",
    },
  ];

  useEffect(() => {
    insertMap();
  }, [marker]);

  const insertMap = () => {
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
      center: [-118.4486, 33.9765], // Initial map center [lng, lat]
      zoom: 10.7, // Initial map zoom level
    });

    marker.forEach((data) => {
      // Create the marker and set its position
      const marker = new mapboxgl.Marker({
        element: createCustomMarkerElement(),
      })
        .setLngLat([data.geometry.location.lng, data.geometry.location.lat])
        .addTo(map);

      // Create and set the popup, then attach it to the marker
      const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
          <h6>${data.name}</h6>
          <p>${data.vicinity}</p>
          <a href="https://www.google.com/maps/place/${data.place_id}" target="_blank">Get Directions</a>
        `);

      // Attach the popup to the marker
      marker.setPopup(popup);
    });

    function createCustomMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(/assets/images/icons/restaurant-new.svg)`;
      el.style.backgroundSize = "contain";
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces({
      latitude: 34.1139,
      longitude: -118.4068,
      type: "auto_garage",
      radius: 8000,
    });
    // console.log(GetNearPlaces.results, "GetFourSquare");
    setMarker(GetNearPlaces.results);
  };
  return (
    <div>
      <h3>Map Box </h3>
      <div id="map" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default MapBoxPractice;
