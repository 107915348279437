import React, { useEffect, useState } from "react";
import { Select } from "antd";
import jData from "./dataFoursquare.json";
import OwlCarousel from "react-owl-carousel";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import AppController from "../controllers/appController";
import GoogleMapsPlaces from "../../services/mapsPlaces";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "sk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2x4b2I2M3phMDZ5cDJqcHMxZnVpMjB3bCJ9.uscOlHG0Tdfk3s2LEGX98w";

function MapViewFunctional(props) {
  const [state, setState] = useState({
    latitude: 0.0,
    longitude: 0.0,
    selectedCategory: "Wings Joint",
    mapRes: [],
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "../assets/images/restaurant-new.svg",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "../assets/images/grocery-1.svg",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "../assets/images/park-1.svg",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "../assets/images/shopping-3.svg",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "../assets/images/entertainment-1.svg",
      },
      {
        name: "Hospitals",
        type: "hospital",
        faicon: "fa fa-h-square",
        icon: "../assets/images/hos-1.svg",
      },
    ],
    type: ["restaurant"],
    typeName: "Restaurants",
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    communityName: "",
    restaurantsArr: [],
    restData: [],
    cityLanding: false,
    Loader: false,
    items: [],
    startPosition: 0,
    categories: [],
    RestaurantsMainArray: [],
    restaurantsList: [],
    typesList: [],
    RestauType: [],
    removeBoundary: false,
    mapText: "",
    activeNumber: -1,
    restaurantsRendered: 0,
    restlist: [],
    restlight: "",
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    restlistarray: [],
    getlistarray: [],
    GetSquare: [],
    activeMaker: null,
    activeNumber: -1,
    propertyInfo: false,
  });
  const [map, setMap] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      // let items =
      //   parseInt(screen.availWidth) <= 767
      //     ? 1
      //     : parseInt(screen.availWidth) <= 991
      //     ? 1
      //     : 1;

      setState((prev) => ({
        ...prev,
        // items: items,
        latitude: props.latitude,
        longitude: props.longitude,
        showlist: false,
        communityName: props.communityName,
        data: props.data,
      }));
      await onetype(state.types[0], 0);
      tab();
    }
    fetchData();
  }, []);
  const insertMap = (markersData, typeName) => {
    const mapInstance = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
      center:
        props.latitude !== 0 &&
        props.latitude !== null &&
        props.longitude !== 0 &&
        props.longitude !== null
          ? {
              lat: props.latitude,
              lng: props.longitude,
            }
          : { lat: 37.7739852, lng: -122.3912407 }, // Initial map center [lng, lat]
      zoom: 13, // Initial map zoom level
    });
    // let markersData = mapState.poi;
    setMap(mapInstance);
    markersData.forEach((data) => {
      // Create the marker and set its position
      const marker = new mapboxgl.Marker({
        element: createCustomMarkerElement(),
      })
        .setLngLat([data.geometry.location.lng, data.geometry.location.lat])
        .addTo(mapInstance);
      if (currentPopup) {
        currentPopup.remove();
      }
      // Create and set the popup, then attach it to the marker
      const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
          <h6>${data.name}</h6>
          <p>${data.vicinity}</p>
          <a href="https://www.google.com/maps/place/?q=place_id:${data.place_id}" target="_blank">Get Directions</a>
        `);

      // Attach the popup to the marker
      marker.setPopup(popup);

      // Handle popup open event
      popup.on("open", () => {
        setCurrentPopup(popup);
        setState((prev) => ({
          ...prev,
          activeMaker: data,
          activeNumber: data.place_id,
          latitude: data.geometry.location.lat,
          longitude: data.geometry.location.lng,
        }));
      });

      // Handle popup close event
      popup.on("close", () => {
        setState((prev) => ({
          ...prev,
          activeNumber: null,
        }));
      });
    });
    let dataArray = [];
    if (props.isProperty) {
      dataArray = Array.isArray(props.data) ? props.data : [props.data];
    }
    if (props.isProperty && Array.isArray(dataArray)) {
      dataArray.forEach((eachdata) => {
        const propertyMarker = new mapboxgl.Marker({
          element: createpropertyMarkerElement(),
        })
          .setLngLat([eachdata.longitude, eachdata.latitude])
          .addTo(mapInstance);

        if (currentPopup) {
          currentPopup.remove();
        }

        const propertypopup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
          <p>${eachdata.address}</p>
        `);
        propertyMarker.setPopup(propertypopup);

        propertypopup.on("open", () => {
          setCurrentPopup(propertypopup);
          setState((prev) => ({ ...prev, propertyInfo: true }));
        });

        propertypopup.on("close", () => {
          setState((prev) => ({ ...prev, propertyInfo: false }));
        });
      });
    } else {
      console.error("props.data is not an array:", dataArray);
    }

    function createpropertyMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(/assets/images/propertyMarker.svg)`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      el.style.backgroundPosition = "center"; // Center the background image
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }
    function createCustomMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      // Determine the image URL based on state.typeName
      let imageUrl;
      switch (typeName) {
        case "Restaurants":
          imageUrl = "/assets/images/icons/restaurant-new.svg";
          break;
        case "Groceries":
          imageUrl = "/assets/images/icons/grocery-1.svg";
          break;
        case "Parks":
          imageUrl = "/assets/images/icons/park-1.svg";
          break;
        case "Shopping":
          imageUrl = "/assets/images/icons/shopping-3.svg";
          break;
        case "Entertainment":
          imageUrl = "/assets/images/icons/entertainment-1.svg";
          break;
        default:
          imageUrl = "/assets/images/icons/hos-1.svg";
          break;
      }
      el.style.backgroundImage = `url(${imageUrl})`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      el.style.backgroundPosition = "center"; // Center the background image
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }
  };
  const onetype = async (row, type) => {
    // console.log(row, "===", type);
    setState((prev) => ({
      ...prev,
      restaurantsArr: [],
      typesList: [],
      restaurantsList: [],
      Loader: true,
    }));
    if (row.type == "restaurant") {
      setState((prev) => ({
        ...prev,
        // type: [row.type],
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
      }));
      let event = "4bf58dd8d48988d14c941735";
      // setState((prev) => ({ ...prev, selectedCategory: event }));

      let marker = props.data;

      // console.log("marker", marker);
      // let GetFourSquare = await AppController.fourSquarData(marker, event);
      let GetFourSquare = await GoogleMapsPlaces.GetNearByPlaces({
        latitude: props.latitude,
        longitude: props.longitude,
        type: "restaurant",
        keyword: "restaurant",
        radius: 2000,
      });

      setState((prev) => ({
        ...prev,
        restlist: GetFourSquare.results,
        GetSquare: "",
        restlistarray: [1, 2],
        latitude: props.latitude,
        longitude: props.longitude,
        Loader: false,
      }));
      insertMap(GetFourSquare.results, row.name);
    } else {
      setState((prev) => ({
        ...prev,
        type: [row.type],
        showlist: false,
        typeName: row.name,
        typeicon: type,
        restlistarray: [],
        Loader: true,
      }));
      let dataaaa = [
        { key: "restaurant", id: "4bf58dd8d48988d116941735" },
        { key: "bar", id: "4bf58dd8d48988d116941735" },
        { key: "supermarket", id: "4bf58dd8d48988d118951735" },
        { key: "park", id: "4bf58dd8d48988d163941735" },
        { key: "shopping_mall", id: "4bf58dd8d48988d1fd941735" },
        { key: "night_club", id: "4d4b7104d754a06370d81259" },
        { key: "hospital", id: "4bf58dd8d48988d196941735" },
      ];
      let obj = dataaaa.find((o) => o.key == row.type.toString());
      // console.log(obj, "obj");
      // let GetSquare = await AppController.fourSquarData(state.data, obj.id);
      // console.log(GetSquare, "GetSquare");
      let GetSquare = await GoogleMapsPlaces.GetNearByPlaces({
        latitude: props.latitude,
        longitude: props.longitude,
        type: obj.key,
        keyword: obj.key,
        radius: 2000,
      });

      setState((prev) => ({
        ...prev,
        getlistarray: [1, 2],
        GetSquare: GetSquare.results,
        restlist: "",
        latitude: props.latitude,
        longitude: props.longitude,
        Loader: false,
      }));
      insertMap(GetSquare.results, row.name);
    }
  };
  const onChangeCategory = async (event) => {
    // console.log(event, "event of selected category");
    setState((prev) => ({ ...prev, selectedCategory: event }));
    // let GetFourSquare = await AppController.fourSquarData(state.data, event);
    // console.log(GetFourSquare, "GetFourSquare");
    let originalString = event;
    let convertedString = originalString.toLowerCase().replace(/ /g, "_");
    // console.log(convertedString,'convertedString');  // Output: arts_&_crafts_store

    let GetFourSquare = await GoogleMapsPlaces.GetNearByPlaces({
      latitude: props.latitude,
      longitude: props.longitude,
      type: convertedString,
      keyword: convertedString,
      radius: 2000,
    });

    setState((prev) => ({
      ...prev,
      restlist: GetFourSquare.results,
      restlistarray: [1, 2],
      latitude: props.latitude,
      longitude: props.longitude,
    }));
    insertMap(GetFourSquare.results, state.typeName);
  };
  const handleMarkerInfo = (data) => {
    // console.log("handlemarker", data);
    if (!map) {
      console.error("Map instance is not available.");
      return;
    }
    setState((prev) => ({
      ...prev,
      activeMaker: data,
      activeNumber: data.place_id,
      latitude: data.geometry.location.lat,
      longitude: data.geometry.location.lng,
    }));
    const { name, vicinity, geometry } = data; // Extract relevant info from row
    // Center the map on the coordinates
    map.flyTo({
      center: [geometry.location.lng, geometry.location.lat], // Update coordinates to your data
      zoom: 13, // Adjust zoom level as needed
    });
    // Close the current popup if it's open
    if (currentPopup) {
      currentPopup.remove();
    }

    // Create a popup with the information
    const popup = new mapboxgl.Popup()
      .setLngLat([geometry.location.lng, geometry.location.lat])
      .setHTML(
        ` <h6>${name}</h6>
          <p>${vicinity}</p>
          <a href="https://www.google.com/maps/place/?q=place_id:${data.place_id}" target="_blank">Get Directions</a>
        `
      )
      .addTo(map);
    // Update the currentPopup state
    setCurrentPopup(popup);
  };
  const clearActiveMarker = () => {
    setState((prev) => ({ ...prev, activeMaker: null }));
  };
  const loadPlacesWithMarkers = async (event) => {
    let GetFourSquare = await AppController.fourSquarData(state.data, event);
  };
  const propertyInfo = (city) => {
    setState((prev) => ({ ...prev, propertyInfo: true }));
  };
  const clearProperty = () => {
    setState((prev) => ({ ...prev, propertyInfo: false }));
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  let children = jData.data;
  let categoryList = [
    { name: "Restaurants", id: "4bf58dd8d48988d116941735" },
    { name: "Groceries", id: "4bf58dd8d48988d118951735" },
    { name: "Parks", id: "4bf58dd8d48988d163941735" },
    { name: "Shopping", id: "4bf58dd8d48988d1fd941735" },
    { name: "Entertainment", id: "4d4b7104d754a06370d81259" },
    { name: "Hospitals", id: "4bf58dd8d48988d196941735" },
  ];
  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  const tab = () => {
    let tabItems =
      parseInt(screen.availWidth) <= 767
        ? 2
        : parseInt(screen.availWidth) <= 991
        ? 2
        : 3;
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  $(document).ready(function () {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 575) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 2,
        margin: 30,
        loop: false,
        dots: false,
      });
    } else if (screenWidth <= 767) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 3,
        margin: 30,
        loop: false,
        dots: false,
      });
    } else {
      $(".client-testimonial-carousel").owlCarousel({
        items: 2,
        margin: 30,
        loop: false,
        dots: false,
      });
    }
  });

  return (
    <div className="row m-0">
      <div className="col-md-12 p-0">
        {" "}
        {/* <div className="">{console.log(state.showlist, "28mar")}</div>{" "} */}
      </div>
      <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
        <div className="relomap-height">
          {/* <GoogleMap
            zoom={12}
            center={
              state.latitude !== 0 &&
              state.latitude !== null &&
              state.longitude !== 0 &&
              state.longitude !== null
                ? {
                    lat: state.latitude,
                    lng: state.longitude,
                  }
                : { lat: 37.7739852, lng: -122.3912407 }
            }
            mapContainerStyle={{
              height: "100%",
              width: "100%",
            }}
            options={{
              zoomControl: true, // Enable zoom controls
              zoomControlOptions: {
                position: window.google.maps.ControlPosition.RIGHT_BOTTOM, // Position of zoom controls
              },
            }}
          >
            {props.isProperty && (
              <Marker
                position={{
                  lat: props.data.latitude,
                  lng: props.data.longitude,
                }}
                icon="/assets/images/propertyMarker.svg"
                onClick={() => propertyInfo()}
              >
                {state.propertyInfo ? (
                  <InfoWindow onCloseClick={clearProperty}>
                    <>
                      <p> {props.data.address}</p>
                    </>
                  </InfoWindow>
                ) : null}
              </Marker>
            )}
            {state.restlist &&
              state.restlist.length > 0 &&
              state.restlist.map((place) => {
                const lat = place.venue.location.lat;
                const lng = place.venue.location.lng;
                const position = { lat, lng };
                return (
                  <Marker
                    key={place.venue.id}
                    position={position}
                    title={place.venue.name}
                    icon="/assets/images/icons/restaurant-new.svg"
                    onClick={() => handleMarkerInfo(place.venue)}
                  >
                    {state.activeMaker &&
                    state.activeMaker.id === place.venue.id ? (
                      <InfoWindow onCloseClick={clearActiveMarker}>
                        <>
                          <h6
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                            }}
                          >
                            {place.venue.name}
                          </h6>
                          <div>
                            <p>
                              {" "}
                              {place.venue.location.formattedAddress.toString()}
                            </p>
                            <a
                              href={`https://www.google.com/maps/place/${place.venue.location.formattedAddress}`}
                              target="_blank"
                            >
                              Get Directions
                            </a>
                          </div>
                        </>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                );
              })}
            {state.GetSquare &&
              state.GetSquare.length > 0 &&
              state.GetSquare.map((place) => {
                const lat = place.venue.location.lat;
                const lng = place.venue.location.lng;
                const position = { lat, lng };
                return (
                  <Marker
                    key={place.venue.id}
                    position={position}
                    title={place.venue.name}
                    icon={
                      state.typeName == "Groceries"
                        ? "/assets/images/icons/grocery-1.svg"
                        : state.typeName == "Parks"
                        ? "/assets/images/icons/park-1.svg"
                        : state.typeName == "Shopping"
                        ? "/assets/images/icons/shopping-3.svg"
                        : state.typeName == "Entertainment"
                        ? "/assets/images/icons/entertainment-1.svg"
                        : "/assets/images/icons/hos-1.svg"
                    }
                    onClick={() => handleMarkerInfo(place.venue)}
                  >
                    {state.activeMaker &&
                    state.activeMaker.id === place.venue.id ? (
                      <InfoWindow onCloseClick={clearActiveMarker}>
                        <>
                          <h6
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                            }}
                          >
                            {place.venue.name}
                          </h6>
                          <div>
                            <p>
                              {" "}
                              {place.venue.location.formattedAddress.toString()}
                            </p>
                            <a
                              href={`https://www.google.com/maps/place/${place.venue.location.formattedAddress}`}
                              target="_blank"
                            >
                              Get Directions
                            </a>
                          </div>
                        </>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                );
              })}
          </GoogleMap> */}
          <div id="map" style={{ width: "100%", height: "100%" }}></div>
        </div>
      </div>

      {state.Loader == true ? <div className="loading">Loading</div> : null}

      <div className="col-md-4 p-0">
        <div className="map-right-view rounded-end-4">
          <span className="toggleArrow" onClick={() => heightView()}>
            <i class="fa-solid fa-angle-up"></i>
          </span>

          <div className="heightGrid">
            <div className="slider mb-3">
              {/* <OwlCarousel
                    className="owl-theme"
                    dots={false}
                    items={state.tabItems}
                    startPosition={state.startPosition}
                    onDragged={(object) => updateCarouselPosition(object)}
                    onTranslated={(object) => updateCarouselPosition(object)}
                  >
                    {state.types.map((type, index) => (
                      <div key={index} className="item">
                        <a
                          className={`nav-item${
                            (state.typeName === ""
                              ? " Restaurants"
                              : state.typeName) === type.name
                              ? " active"
                              : ""
                          }`}
                          data-toggle="tab"
                          id={type.name.replace(/[^a-zA-Z]/g, "")}
                          onClick={() => onetype(type, index)}
                        >
                          <i className={type.faicon}></i>
                          {type.name}
                        </a>
                      </div>
                    ))}
                  </OwlCarousel> */}
              <div class="owl-carousel client-testimonial-carousel">
                {state.types.map((type, index) => (
                  <div key={index} className="item">
                    <a
                      className={`nav-item${
                        (state.typeName === ""
                          ? " Restaurants"
                          : state.typeName) === type.name
                          ? " active"
                          : ""
                      }`}
                      data-toggle="tab"
                      id={type.name.replace(/[^a-zA-Z]/g, "")}
                      onClick={() => onetype(type, index)}
                    >
                      <i className={type.faicon}></i>
                      {type.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            {state.typeName == "Restaurants" &&
              state.restlistarray &&
              state.restlistarray.length > 0 && (
                <>
                  <div id="multi-rest">
                    <Select
                      value={state.selectedCategory}
                      placeholder="Please select a City"
                      onChange={(event) => onChangeCategory(event)}
                    >
                      {/* <Select.Option value="" key={0}>
                Please Select from the list
              </Select.Option> */}
                      {_.sortBy(children, [
                        function (o) {
                          return o;
                        },
                      ]).map((row, i) => {
                        return (
                          <Select.Option value={row.name} key={i}>
                            {row.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="point-tails">
                    {state.restlist && state.restlist.length > 0 ? (
                      state.restlist.map((row, index) => {
                        return (
                          <div
                            className={
                              row.place_id == state.activeNumber
                                ? "tail-view active"
                                : "tail-view"
                            }
                            key={index}
                            // onClick={() => mapIndexChange(row, index)}
                            onClick={() => handleMarkerInfo(row)}
                          >
                            {/* {row.photos && (
                              <div className="image">
                                <img
                                  src={
                                    "https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photoreference=" +
                                    row.photos[0].photo_reference +
                                    "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc"
                                  }
                                />
                              </div>
                            )} */}
                            <ul>
                              <li>
                                <h1>{row.name}</h1>
                              </li>
                              <li>
                                <span>{row.vicinity.toString()}</span>
                              </li>
                            </ul>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no-content-msg MiddleText">
                        There are no points of interest available. Please select
                        another category.
                      </h3>
                    )}
                  </div>
                </>
              )}
            {state.typeName != "Restaurants" &&
              state.getlistarray &&
              state.getlistarray.length > 0 && (
                <div className="point-tails">
                  {state.GetSquare && state.GetSquare.length > 0 ? (
                    state.GetSquare.map((row, index) => {
                      return (
                        <div
                          className={
                            row.place_id == state.activeNumber
                              ? "tail-view active"
                              : "tail-view"
                          }
                          key={index}
                          //   onClick={() => mapIndexChangeTypes(row, index)}
                          onClick={() => handleMarkerInfo(row)}
                        >
                          {/* {row.photos && (
                            <div className="image">
                              <img
                                src={
                                  "https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photoreference=" +
                                  row.photos[0].photo_reference +
                                  "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc"
                                }
                              />
                            </div>
                          )} */}
                          <ul>
                            <li>
                              <h1>{row.name}</h1>
                            </li>
                            <li>
                              <span>{row.vicinity.toString()}</span>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="no-content-msg MiddleText">
                      There are no data available at this time.
                    </h3>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapViewFunctional;
